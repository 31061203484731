import moment from "moment";

import { sanitizeInputString } from "@interactive-investor/onestack-web-shared";

import ApiService from "../api";

import { VirtualPortfolioPOSTPUTInterface } from "./interfaces/virtualPortfolio";

/**
 * Virtual Portfolio Headers
 */
const getPortfolios = (accessToken: string) =>
  ApiService.get(`free/api/1/virtual-portfolio`, undefined, accessToken);

const getPortfolio = (id: string, accessToken: string) =>
  ApiService.get(`free/api/1/virtual-portfolio/${id}`, undefined, accessToken);

const createPortfolio = (portfolioName: string, accessToken: string) =>
  ApiService.post(
    `free/api/1/virtual-portfolio`,
    { name: sanitizeInputString(portfolioName) },
    accessToken
  );

const createPortfolioWithItem = (
  item: VirtualPortfolioPOSTPUTInterface,
  accessToken: string
) =>
  ApiService.post(
    `free/api/1/virtual-portfolio/item`,
    {
      instrumentType: sanitizeInputString(item.instrumentType),
      name: sanitizeInputString(item.name),
      newPortfolioName: sanitizeInputString(item.newPortfolioName as string),
      notes: sanitizeInputString(item.notes),
      purchaseDate: moment(item.purchaseDate, "YYYY-MM-DD", true).format(
        "YYYY-MM-DD"
      ),
      purchasePrice: Number(item.purchasePrice),
      sedol: sanitizeInputString(item.sedol),
      units: Number(item.units),
    },
    accessToken
  );

const updatePortfolio = (
  portfolioId: string,
  newPortfolioName: string,
  accessToken: string
) =>
  ApiService.put(
    `free/api/1/virtual-portfolio/${portfolioId}`,
    { name: sanitizeInputString(newPortfolioName) },
    accessToken
  );

const deletePortfolio = (portfolioId: string, accessToken: string) =>
  ApiService.delete(`free/api/1/virtual-portfolio/${portfolioId}`, accessToken);

/**
 * Virtual Portfolio Items
 */
const getPortfolioItems = (
  portfolioId: string,
  portfolioItemTypeConstant: string,
  pageNumber: number,
  accessToken: string
) => {
  const params = {
    pageNo: pageNumber || 1,
  };

  if (portfolioItemTypeConstant === "ALL") {
    return ApiService.get(
      `free/api/1/virtual-portfolio/${portfolioId}/item`,
      params,
      accessToken
    );
  }

  return ApiService.get(
    `free/api/1/virtual-portfolio/${portfolioId}/item/${portfolioItemTypeConstant}`,
    params,
    accessToken
  );
};

const createPortfolioItem = (
  portfolioId: string,
  item: VirtualPortfolioPOSTPUTInterface,
  accessToken: string
) =>
  ApiService.post(
    `free/api/1/virtual-portfolio/${portfolioId}/item`,
    {
      instrumentType: sanitizeInputString(item.instrumentType),
      name: sanitizeInputString(item.name),
      notes: sanitizeInputString(item.notes),
      purchaseDate: moment(item.purchaseDate, "YYYY-MM-DD", true).format(
        "YYYY-MM-DD"
      ),
      purchasePrice: Number(item.purchasePrice),
      sedol: sanitizeInputString(item.sedol),
      units: Number(item.units),
    },
    accessToken
  );

const updatePortfolioItem = (
  portfolioId: string,
  portfolioItemId: string,
  item: VirtualPortfolioPOSTPUTInterface,
  accessToken: string
) =>
  ApiService.put(
    `free/api/1/virtual-portfolio/${portfolioId}/item/${portfolioItemId}`,
    {
      instrumentType: sanitizeInputString(item.instrumentType),
      name: sanitizeInputString(item.name),
      notes: sanitizeInputString(item.notes),
      purchaseDate: moment(item.purchaseDate, "YYYY-MM-DD", true).format(
        "YYYY-MM-DD"
      ),
      purchasePrice: Number(item.purchasePrice),
      sedol: sanitizeInputString(item.sedol),
      units: Number(item.units),
    },
    accessToken
  );

const deletePortfolioItem = (
  portfolioId: string,
  portfolioItemId: string,
  accessToken: string
) =>
  ApiService.delete(
    `free/api/1/virtual-portfolio/${portfolioId}/item/${portfolioItemId}`,
    accessToken
  );

/**
 * Create the service and export
 */
const VirtualPortfolioService = {
  getPortfolios,
  getPortfolio,
  createPortfolio,
  createPortfolioWithItem,
  updatePortfolio,
  deletePortfolio,
  getPortfolioItems,
  createPortfolioItem,
  updatePortfolioItem,
  deletePortfolioItem,
};

export default VirtualPortfolioService;
