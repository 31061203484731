import * as React from "react";

import { Button } from "@interactive-investor/onestack-web-shared";

import { VPortfolioDeletePortfolioItemModalProps } from "./interfaces/props";

import { deletePortfolioStyles as styles } from "./styles/delete";

const VPortfolioDeletePortfolioItemModal: React.FC<VPortfolioDeletePortfolioItemModalProps> = ({
  portfolioItemId,
  onDeletePortfolioItem,
  onCloseButtonClick,
}) => {
  return (
    <div css={styles.container}>
      <p css={styles.notice}>
        Please confirm that you want to permanently delete this row. Keep in
        mind that this action can not be reversed.
      </p>

      <div css={styles.button_container}>
        <Button
          type="secondary"
          size="medium"
          onClick={() => onDeletePortfolioItem(portfolioItemId)}
        >
          Delete
        </Button>
      </div>

      <div css={styles.button_container}>
        <Button
          type="link"
          size="medium"
          onClick={() => onCloseButtonClick(portfolioItemId)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default VPortfolioDeletePortfolioItemModal;
