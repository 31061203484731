import { Currency } from "@ii/onestack-shared";

import { defaultDecimalPlaces } from "@interactive-investor/onestack-web-shared";

export const handlePriceUpdateItemState = (props: {
  key: string;
  val: number;
  purchaseCurrency?: Currency | null;
  item: any;
  totalCost: number;
  perShareCostActive: boolean;
  totalCostActive: boolean;
}) => {
  let newState: any = {};
  let newItemState: any = {};

  let totalCostMultiplier = 1;
  if (props.purchaseCurrency && props.purchaseCurrency.code === "GBX") {
    totalCostMultiplier = 100;
  }

  const value = Number(props.val);

  switch (props.key) {
    case "units":
      newItemState.units = value;
      if (value === 0) {
        newState.totalCost = 0;
      }

      if (props.perShareCostActive && value > 0) {
        const unitsTotalCost =
          (value * props.item.purchasePrice) / totalCostMultiplier;
        newState.totalCost = unitsTotalCost.toFixed(defaultDecimalPlaces);
      }

      if (props.totalCostActive && value > 0) {
        const purchasePrice = (props.totalCost / value) * totalCostMultiplier;
        newItemState.purchasePrice = purchasePrice.toFixed(
          defaultDecimalPlaces
        );
      }
      break;
    case "perShareCost":
      newItemState.purchasePrice = value;
      const perShareCostTotalCost =
        (value * props.item.units) / totalCostMultiplier;
      newState.totalCost = perShareCostTotalCost.toFixed(defaultDecimalPlaces);
      break;
    case "totalCost":
      newState.totalCost = value;
      const totalCostPurchasePrice =
        (value / props.item.units) * totalCostMultiplier;
      if (totalCostPurchasePrice > 0) {
        newItemState.purchasePrice = totalCostPurchasePrice.toFixed(
          defaultDecimalPlaces
        );
      }
      break;
    default:
      newItemState[props.key] = value;
  }

  return {
    ...newState,
    item: {
      ...props.item,
      ...newItemState,
    },
  };
};
