import * as React from "react";

import { EmotionStyles } from "@interactive-investor/onestack-web-shared";

interface ItemsTableFilterProps {
  styles: EmotionStyles;
  filterItemType: string;
  onFilterItemType: (itemTypeConstant: string) => void;
  innerHtml: JSX.Element;
}

export const ItemsTableFilter: React.FC<ItemsTableFilterProps> = (props) => {
  const filters = [
    { key: "ALL", label: "all investments" },
    { key: "COMPANY", label: "companies" },
    { key: "FUND", label: "funds" },
    { key: "INVESTMENT_TRUST", label: "investment trusts" },
    { key: "ETF", label: "ETFs" },
  ];

  return (
    <div css={props.styles.nav_container}>
      <ul css={props.styles.nav}>
        {filters.map((filter, i) => (
          <li
            css={[
              props.styles.nav_item,
              props.filterItemType === filter.key
                ? props.styles.nav_item_active
                : {},
            ]}
            onClick={() => props.onFilterItemType(filter.key)}
            key={i}
          >
            {filter.label}
          </li>
        ))}
      </ul>
      <div>{props.innerHtml}</div>
    </div>
  );
};

export default ItemsTableFilter;
