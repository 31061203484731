import * as React from "react";

import VirtualPortfolioItemsListWithDetail from "../../../components/VirtualPortfolio/ItemsListWithDetail";

import { VPortfolioViewEditViewProps } from "../interfaces/props";

const VPortfolioViewEditView = ({
  containerClass,
  portfolio,
  onDeletePortfolioItem,
  editViewActiveItemId,
  onUpdatePortfolioItemSuccess,
}: VPortfolioViewEditViewProps) => (
  <div className={`${containerClass || ""} GridRow`}>
    <div className="GridCell-xs-auto">
      <VirtualPortfolioItemsListWithDetail
        portfolio={portfolio}
        onDeletePortfolioItem={(portfolioItemId: string) =>
          onDeletePortfolioItem(portfolioItemId)
        }
        activeItemIdOnLoad={editViewActiveItemId}
        onUpdatePortfolioItemSuccess={() => onUpdatePortfolioItemSuccess()}
      />
    </div>
  </div>
);

export default VPortfolioViewEditView;
