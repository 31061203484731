import * as React from "react";

import { Button } from "@interactive-investor/onestack-web-shared";

import VirtualPortfolioEditItemForm from "./components/EditItemForm";

import ItemsListCards from "./components/ItemsListCards";

import { VirtualPortfolioItemsListWithDetailProps } from "./interfaces/props";
import { VirtualPortfolioItemsListWithDetailState } from "./interfaces/state";

import { itemsListWithDetailStyles as styles } from "./styles/itemsListWithDetail";

class VirtualPortfolioItemsListWithDetail extends React.Component<
  VirtualPortfolioItemsListWithDetailProps,
  VirtualPortfolioItemsListWithDetailState
> {
  constructor(props: VirtualPortfolioItemsListWithDetailProps) {
    super(props);
    this.state = {
      activePortfolioItemId:
        props.activeItemIdOnLoad || this.getFirstPortfolioItemId(),
    };
  }

  getFirstPortfolioItemId = () => {
    return this.props.portfolio.items.length > 0
      ? this.props.portfolio.items[0].id
      : "";
  };

  getPortfolioItemById = (portfolioItemId: string) => {
    return this.props.portfolio.items.length > 0
      ? this.props.portfolio.items.find((item) => item.id === portfolioItemId)
      : undefined;
  };

  onChangeActivePortfolioItem = (portfolioItemId: string) => {
    this.setState({
      activePortfolioItemId: portfolioItemId,
    });
  };

  onDeleteActivePortfolioItem = () => {
    if (this.state.activePortfolioItemId) {
      this.props.onDeletePortfolioItem(this.state.activePortfolioItemId);
    }
  };

  render() {
    const { portfolio } = this.props;
    const { activePortfolioItemId } = this.state;

    const activePortfolioItem = this.getPortfolioItemById(
      activePortfolioItemId
    );

    return (
      <div css={styles.container}>
        <ItemsListCards
          styles={styles}
          portfolio={portfolio}
          activeItemId={activePortfolioItemId}
          onItemClick={(portfolioItemId: string) =>
            this.onChangeActivePortfolioItem(portfolioItemId)
          }
        />

        <div css={styles.item_container}>
          {!activePortfolioItem && (
            <div className="GridRow">
              <div className="GridCell-xs-auto">
                <p>Please select an item from the list</p>
              </div>
            </div>
          )}

          {activePortfolioItem && (
            <>
              <div className="GridRow" css={styles.item_header}>
                <div className="GridCell-xs-grow">
                  <h2>{activePortfolioItem.name}</h2>
                </div>
                <div className="GridCell-xs-shrink">
                  <Button
                    type="link"
                    size="small"
                    leftIcon="trash-alt"
                    leftIconSet="fal"
                    onClick={() => this.onDeleteActivePortfolioItem()}
                  >
                    Delete Row
                  </Button>
                </div>
              </div>

              <div className="GridRow">
                <div className="GridCell-xs-auto">
                  <VirtualPortfolioEditItemForm
                    portfolioItem={activePortfolioItem}
                    buttonContainerClass={styles.button_container}
                    onUpdatePortfolioItemSuccess={() =>
                      this.props.onUpdatePortfolioItemSuccess()
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default VirtualPortfolioItemsListWithDetail;
