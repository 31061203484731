import { isNil } from "lodash";
import * as React from "react";

import {
  compareNumberAsc,
  compareNumberDesc,
  compareStringAsc,
  compareStringDesc,
  ComparisonTable,
  ComparisonTableColumnInterface,
  Icon,
  IconWithCounter,
  MenuDropdown,
  MenuDropdownItem,
  Modal,
  OneSiteLink,
  renderPercentage,
  renderPrice,
  TableSortOrderEnum,
} from "@interactive-investor/onestack-web-shared";

import {
  VirtualPortfolioFlattenedItemInterface,
  VirtualPortfolioItemInterface,
  VirtualPortfolioItemTotalInterface,
} from "../../services/virtual-portfolio/interfaces/virtualPortfolio";
import ActionsHelpModal from "../VirtualPortfolio/ActionsHelpModal";

import ItemsTableFilter from "./components/ItemsTableFilter";

import { VirtualPortfolioItemsTableProps } from "./interfaces/props";
import { VirtualPortfolioItemsTableState } from "./interfaces/state";

import { itemsTableStyles as styles } from "./styles/itemsTable";

class VirtualPortfolioItemsTable extends React.Component<
  VirtualPortfolioItemsTableProps,
  VirtualPortfolioItemsTableState
> {
  constructor(props: VirtualPortfolioItemsTableProps) {
    super(props);

    this.state = {
      actionsHelpModalShow: false,
      portfolioItemTypeConstant: props.portfolioItemTypeFilter,
      sortBy: "name",
      sortOrder: TableSortOrderEnum.ASC,
    };
  }

  onFilterPortfolioItemType = (portfolioItemTypeConstant: string) => {
    this.setState(
      {
        portfolioItemTypeConstant: portfolioItemTypeConstant,
      },
      () => {
        this.props.onFilterPortfolioItemType(portfolioItemTypeConstant);
      }
    );
  };

  handleModalCloseOnEsc = (e: KeyboardEvent) => {
    const escapeKey = e.keyCode === 27;
    if (escapeKey && this.state.actionsHelpModalShow) {
      this.toggleActionsModal();
    }
  };

  toggleActionsModal = () => {
    this.setState(
      {
        actionsHelpModalShow: !this.state.actionsHelpModalShow,
      },
      () => {
        if (this.state.actionsHelpModalShow) {
          document.addEventListener("keyup", this.handleModalCloseOnEsc);
        } else {
          document.removeEventListener("keyup", this.handleModalCloseOnEsc);
        }
      }
    );
  };

  getColumns = (): Array<ComparisonTableColumnInterface> => [
    {
      cellType: "name",
      key: "name",
      label: (
        <span>
          Name
          <Icon
            className="DataTable__cell__name__icon"
            set="far"
            identifier="arrows-alt-h"
          />
        </span>
      ),
      sortConstant: "name",
      sortable: true,
      visible: true,
    },
    {
      cellType: "action",
      icon: true,
      iconClick: this.toggleActionsModal,
      key: "actions",
      label: "Actions",
      sortConstant: "actions",
      sortable: false,
      visible: true,
    },
    {
      cellType: "number",
      key: "valuationPrice",
      label: "Price",
      sortConstant: "valuationPrice",
      sortable: false,
      visible: true,
    },
    {
      cellType: "number",
      key: "dayPriceChange",
      label: "Day change",
      sortConstant: "dayPriceChange",
      sortable: false,
      visible: true,
    },
    {
      cellType: "number",
      key: "dayPricePercentageChange",
      label: "% change",
      sortConstant: "dayPricePercentageChange",
      sortable: true,
      visible: true,
    },
    {
      cellType: "number",
      key: "units",
      label: "Holding",
      sortConstant: "units",
      sortable: false,
      visible: true,
    },
    {
      cellType: "number",
      key: "purchasePrice",
      label: "AVG price",
      sortConstant: "purchasePrice",
      sortable: false,
      visible: true,
    },
    {
      cellType: "number",
      key: "bookCost",
      label: "Book cost",
      sortConstant: "bookCost",
      sortable: true,
      visible: true,
    },
    {
      cellType: "number",
      key: "value",
      label: "Value",
      sortConstant: "value",
      sortable: true,
      visible: true,
    },
    {
      cellType: "number",
      key: "gain",
      label: "Gain",
      sortConstant: "gain",
      sortable: true,
      visible: true,
    },
    {
      cellType: "number",
      key: "gainPercentage",
      label: "%",
      sortConstant: "gainPercentage",
      sortable: false,
      visible: true,
    },
    {
      cellType: "notes",
      key: "notes",
      label: "",
      sortConstant: "notes",
      sortable: false,
      visible: true,
    },
  ];

  onSortItems = (sortBy: string, sortOrder: TableSortOrderEnum) => {
    this.setState({
      sortBy,
      sortOrder,
    });
  };

  sortFunction = (a: any, b: any): number => {
    let sortFunction: (sortBy: string, a: any, b: any) => number;

    switch (this.state.sortBy) {
      case "name":
        sortFunction =
          this.state.sortOrder === TableSortOrderEnum.ASC
            ? compareStringDesc
            : compareStringAsc;
        break;
      case "category":
        sortFunction =
          this.state.sortOrder === TableSortOrderEnum.DESC
            ? compareStringDesc
            : compareStringAsc;
        break;
      default:
        sortFunction =
          this.state.sortOrder === TableSortOrderEnum.DESC
            ? compareNumberDesc
            : compareNumberAsc;
    }

    return sortFunction(this.state.sortBy, a, b);
  };

  flattenItem = (item: VirtualPortfolioItemInterface) => {
    const newItem: VirtualPortfolioFlattenedItemInterface = {
      bookCost: item.bookCost,
      contentItemCounts: item.contentItemCounts ? item.contentItemCounts : [],
      currency: item.price ? item.price.currency : null,
      dayPriceChange: item.price ? item.price.dayPriceChange : null,
      dayPricePercentageChange: item.price
        ? item.price.dayPricePercentageChange
        : null,
      gain: item.valuation ? item.valuation.gainLoss : null,
      gainPercentage: item.valuation ? item.valuation.gainLossPercentage : null,
      hasRns: item.hasRns ? item.hasRns : false,
      id: item.id,
      name: item.name,
      notes: item.notes,
      price: item.price,
      purchasePrice: item.purchasePrice,
      status: item.status,
      type: item.type,
      units: item.units,
      urlId: `${process.env.GATSBY_MAIN_SITE_URL || ""}${item.urlId}`,
      valuation: item.valuation,
      valuationPrice: item.price ? item.price.valuation : null,
      value: item.valuation ? item.valuation.currentValue : null,
    };

    return newItem;
  };

  render() {
    const {
      portfolio,
      onEditPortfolioItem,
      onDeletePortfolioItem,
    } = this.props;

    const hasRNS = (item: VirtualPortfolioFlattenedItemInterface) => {
      return item.contentItemCounts.find((count) => count.source === "RNS");
    };

    const newItems = portfolio.items.map(this.flattenItem);
    newItems.sort((a, b) => this.sortFunction(a, b));

    let items: Array<any> = newItems.map((item) => {
      const showNewsIcon = !!hasRNS(item);
      const actionsItems: Array<MenuDropdownItem> = [
        {
          icon: "sticky-note",
          iconSet: "fal",
          label: "notes",
          onClick: () => onEditPortfolioItem(item.id),
          onClickLabelChange: false,
        },
        {
          icon: "pen",
          iconSet: "fal",
          label: "edit",
          onClick: () => onEditPortfolioItem(item.id),
          onClickLabelChange: false,
        },
        {
          icon: "trash",
          iconSet: "fal",
          label: "delete",
          onClick: () => onDeletePortfolioItem(item.id),
          onClickLabelChange: false,
        },
      ];

      let rnsCount = 0;
      const rnsCountObject = hasRNS(item);
      if (rnsCountObject) {
        rnsCount = rnsCountObject ? rnsCountObject.count : 0;
        const rnsActionItem: MenuDropdownItem = {
          counter: rnsCount,
          icon: "newspaper",
          iconSet: "fal",
          label: "regulatory news",
          onClick: () => window.open(`${item.urlId}/regulatory-news`, "_blank"),
          onClickLabelChange: false,
        };

        actionsItems.splice(0, 0, rnsActionItem);
      }

      let name: JSX.Element;
      if (!isNil(item.urlId == null) && item.urlId == null) {
        name = (
          <span className="DataTable__cell--no-url DataTable__cell--unsupported">
            {item.name}
          </span>
        );
      } else {
        name = (
          <OneSiteLink
            to={item.urlId ? item.urlId : ""}
            title={item.name}
            target="_blank"
          >
            {item.name}
          </OneSiteLink>
        );
      }

      return {
        ...item,
        actions: (
          <div css={styles.action_wrapper}>
            {showNewsIcon && (
              <OneSiteLink
                to={`${item.urlId}/regulatory-news`}
                title="Regulatory News"
                cssStyles={[
                  styles.actionsIcon,
                  styles.firstAction,
                  !rnsCountObject ? styles.actionsIconHidden : {},
                ]}
                target="_blank"
              >
                <IconWithCounter
                  cssStyles={[styles.actionsIcon, styles.actionsIconNoMargin]}
                  identifier="newspaper"
                  set="fal"
                  title="Regulatory news"
                  tabIndex={0}
                  count={rnsCount > 0 ? rnsCount : undefined}
                />
              </OneSiteLink>
            )}

            <Icon
              cssStyles={[styles.actionsIcon]}
              onClick={() => this.props.onEditPortfolioItem(item.id)}
              identifier="pen"
              set="fal"
              title="Edit"
              tabIndex={0}
            />
            <MenuDropdown
              label={
                <Icon
                  cssStyles={[styles.actionsIcon]}
                  identifier="ellipsis-h"
                  set="far"
                  title="All actions"
                />
              }
              items={actionsItems}
              showArrow={false}
              size={"tiny"}
              dividerIndex={rnsCountObject ? 2 : 1}
            />
          </div>
        ),
        bookCost:
          item.bookCost && item.price && item.price.currency ? renderPrice(
                item.price.currency.code === "GBX"
                  ? "£"
                  : item.price.currency.prefix,
                item.price.currency.code === "GBX"
                  ? ""
                  : item.price.currency.suffix,
                item.bookCost,
                undefined,
                false
              )
            : "-",
        category:
          item.status !== "OK" || item.type == null
            ? "unavailable"
            : item.type.toLowerCase().replace("_", " "),
        dayPriceChange:
          item.price &&
          item.price.dayPriceChange !== null &&
          item.price.currency
            ? renderPrice(
                item.price.currency.prefix,
                item.price.currency.suffix,
                item.price.dayPriceChange,
                undefined,
                true,
                item.type === "FUND" ? 3 : undefined
              )
            : "-",
        dayPricePercentageChange:
          item.price && item.price.dayPricePercentageChange !== null
            ? renderPercentage(item.price.dayPricePercentageChange)
            : "-",
        error: item.status === "UNSUPPORTED",
        gain:
          item.valuation && typeof item.valuation.gainLoss !== "undefined"
            ? renderPrice(
                item.valuation.currency ? item.valuation.currency.prefix : "",
                item.valuation.currency ? item.valuation.currency.suffix : "",
                item.valuation.gainLoss
              )
            : "-",
        gainPercentage:
          item.valuation &&
          typeof item.valuation.gainLossPercentage !== "undefined"
            ? renderPercentage(item.valuation.gainLossPercentage)
            : "-",
        name: name,
        noUrl: item.urlId === null,
        notes:
          item.notes && item.notes !== "" ? (
            <Icon
              tabIndex={0}
              onClick={() => this.props.onEditPortfolioItem(item.id)}
              title="Notes"
              cssStyles={[styles.actionsIcon]}
              identifier="sticky-note"
              set="fal"
            />
          ) : (
            ""
          ),
        purchasePrice:
          item.purchasePrice && item.price && item.price.currency
            ? renderPrice(
                item.price.currency.prefix,
                item.price.currency.suffix,
                item.purchasePrice,
                undefined,
                false
              )
            : item.purchasePrice,
        units: item.units,
        url: item.urlId,
        valuationPrice:
          item.status === "UNSUPPORTED"
            ? "unsupported*"
            : item.price && item.price.valuation && item.price.currency
            ? renderPrice(
                item.price.currency.prefix,
                item.price.currency.suffix,
                item.price.valuation,
                undefined,
                false,
                item.type === "FUND" ? 3 : undefined
              )
            : "-",
        value:
          item.valuation &&
          item.valuation.currentValue &&
          item.valuation.currency
            ? renderPrice(
                item.valuation.currency.prefix,
                item.valuation.currency.suffix,
                item.valuation.currentValue,
                undefined,
                false
              )
            : "-",
      };
    });

    items = items.concat(
      portfolio.totals.map((total: VirtualPortfolioItemTotalInterface, i) => ({
        bookCost:
          total.bookcost && total.currency
            ? renderPrice(
                total.currency.prefix,
                total.currency.suffix,
                total.bookcost,
                undefined,
                false
              )
            : "-",
        className: styles.row_total,
        gain:
          total.gain && total.currency
            ? renderPrice(
                total.currency.prefix,
                total.currency.suffix,
                total.gain
              )
            : "-",
        gainPercentage: total.percentage
          ? renderPercentage(total.percentage)
          : "-",
        name: total.currency ? `totals ${total.currency.code}` : "totals",
        noUrl: true,
        value:
          total.valuation && total.currency
            ? renderPrice(
                total.currency.prefix,
                total.currency.suffix,
                total.valuation,
                undefined,
                false
              )
            : "-",
      }))
    );

    return (
      <>
        <div css={styles.container}>
          <ItemsTableFilter
            styles={styles}
            filterItemType={this.props.portfolioItemTypeFilter}
            onFilterItemType={(portfolioItemTypeConstant: string) =>
              this.onFilterPortfolioItemType(portfolioItemTypeConstant)
            }
            innerHtml={
              <>
                Prices delayed by 15 min or more.{" "}
                <a
                  onClick={() =>
                    this.onFilterPortfolioItemType(
                      this.state.portfolioItemTypeConstant || "ALL"
                    )
                  }
                >
                  refresh
                </a>
              </>
            }
          />

          {items.length === 0 && (
            <p>
              Use <strong>search &amp; add</strong> or visit an investment page
              and use <i>add to virtual portfolio</i>.
            </p>
          )}

          {items.length > 0 && (
            <ComparisonTable
              title=""
              name="virtual-portfolio-table"
              columns={this.getColumns()}
              data={items}
              cssStyles={[styles.table_container]}
              tableClassName={styles.table}
              sortBy={this.state.sortBy}
              onSort={(sortBy: string, sortOrder: TableSortOrderEnum) =>
                this.onSortItems(sortBy, sortOrder)
              }
              showItems={1000}
              showMore={() => null}
              disableShowMore={true}
            />
          )}
        </div>

        <Modal
          show={this.state.actionsHelpModalShow}
          id="vportfolio-actions-help-modal"
          size="medium"
          onClose={() => this.setState({ actionsHelpModalShow: false })}
        >
          <ActionsHelpModal
            onCloseButtonClick={() => this.toggleActionsModal()}
          />
        </Modal>
      </>
    );
  }
}

export default VirtualPortfolioItemsTable;
