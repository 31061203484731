import * as React from "react";

import {
  Button,
  ButtonTypeEnum,
  Input,
  navigateTo,
  sanitizeInputString,
} from "@interactive-investor/onestack-web-shared";

import { withAuth } from "../../../contexts/Auth";
import { AuthContextInjectedProps } from "../../../contexts/Auth/interfaces/context";
import {
  ApiDataError,
  ApiError,
} from "../../../services/api/interfaces/apiError";
import VirtualPortfolioService from "../../../services/virtual-portfolio";

import { VirtualPortfolioCreatePortfolioFormProps } from "../interfaces/props";
import { VirtualPortfolioCreatePortfolioFormState } from "../interfaces/state";

import { createPortfolioFormStyles as styles } from "../styles/createPortfolio";

export type WithAuthProps = VirtualPortfolioCreatePortfolioFormProps &
  AuthContextInjectedProps;

class VirtualPortfolioCreatePortfolioForm extends React.Component<
  WithAuthProps,
  VirtualPortfolioCreatePortfolioFormState
> {
  constructor(props: WithAuthProps) {
    super(props);

    this.state = {
      error: undefined,
      portfolioName: "",
      processing: false,
    };
  }

  onPortfolioNameChange = (name: string) => {
    this.setState({
      portfolioName: name,
    });
  };

  onSubmit = () => {
    if (this.state.processing !== true) {
      this.setState({
        processing: true,
      });

      VirtualPortfolioService.createPortfolio(
        sanitizeInputString(this.state.portfolioName),
        this.props.auth.getAccessTokenFree() as string
      )
        .then((response: any) => {
          if (response.id) {
            navigateTo(`/virtual-portfolio/${response.id}`);
            return;
          }
        })
        .catch((error: ApiError) => {
          console.error(error.data);
          this.setState({ error: error.data as ApiDataError });
        });
    }
  };

  render() {
    const {
      buttonContainerClass,
      buttonType,
      portfolioNameLabel,
      portfolioNamePlaceholder,
    } = this.props;

    const formGeneralErrors =
      this.state.error &&
      this.state.error.errors.filter(
        (error: any) =>
          error.field === "" ||
          error.field === null ||
          typeof error.field === "undefined"
      );

    const portfolioNameError =
      this.state.error &&
      this.state.error.errors.find((error: any) => error.field === "name");

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          this.onSubmit();
        }}
        css={styles.container}
      >
        <Input
          name="portfolioName"
          type="text"
          label={portfolioNameLabel || "Name of new virtual portfolio"}
          helper={portfolioNamePlaceholder || "Enter name..."}
          value={this.state.portfolioName}
          onChange={(name: string) => this.onPortfolioNameChange(name)}
          autoComplete={false}
          readOnly={this.state.processing}
          error={portfolioNameError ? portfolioNameError.message : undefined}
        />

        <div css={buttonContainerClass}>
          <Button
            type={buttonType ? ButtonTypeEnum[buttonType] : "secondary"}
            size="medium"
            cssStyles={[styles.button]}
          >
            create virtual portfolio
          </Button>
        </div>

        {formGeneralErrors && (
          <div className="FormErrors__container">
            {formGeneralErrors.length === 0 && (
              <p>Please check the form for errors</p>
            )}
            {formGeneralErrors.length > 0 &&
              formGeneralErrors.map((error, i) => (
                <p key={i}>{error.message}</p>
              ))}
          </div>
        )}
      </form>
    );
  }
}

export default withAuth(VirtualPortfolioCreatePortfolioForm);
