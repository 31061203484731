import {
  colors,
  EmotionStyles,
  grid,
  type,
  variables,
} from "@interactive-investor/onestack-web-shared";

export const itemsTableStyles: EmotionStyles = {
  action_wrapper: {
    textAlign: "right",
  },

  actionsIcon: {
    color: `${colors.grey.dark} !important`,
    cursor: "pointer",
    marginLeft: variables.marginMd,

    "&:hover": {
      color: `${colors.secondaryAction} !important`,
    },
  },

  actionsIconHidden: {
    visibility: "hidden",
  },

  firstAction: {
    marginLeft: "0px",
  },

  actionsIconNoMargin: {
    marginLeft: "0px",
  },

  nav_container: {
    "& div": {
      ...type.textSmall,
    },
    margin: `${variables.marginMd} 0 ${variables.marginLg} 0`,

    [grid.breakpointMixins.md]: {
      "& div": {
        flexShrink: 1,
        padding: `0 ${variables.paddingSm} ${variables.paddingXs} ${variables.paddingSm}`,
        textAlign: "right",
      },
      alignItems: "flex-start",
      display: "flex",
    },
  },

  nav: {
    display: "flex",
    flexGrow: 1,
    flexWrap: "wrap",
    listStyleType: "none",
    margin: `0 0 ${variables.marginSm} 0`,
    [grid.breakpointMixins.md]: {
      flexWrap: "nowrap",
      margin: 0,
    },
  },

  nav_item: {
    ...type.textParagraph,
    padding: `0 ${variables.paddingSm} ${variables.paddingXs} ${variables.paddingSm}`,

    "&:hover": {
      color: colors.secondaryAction,
      cursor: "pointer",
    },
  },

  nav_item_active: {
    borderBottom: `2px solid ${colors.secondaryAction}`,
  },

  table_container: {
    overflowY: "visible",

    "& .DataTable-wrapper": {
      overflowX: "scroll",

      [grid.breakpointMixins.xl]: {
        overflowX: "visible",
      },
    },
  },

  table: {
    marginBottom: "4rem",
    [grid.breakpointMixins.lg]: {
      marginBottom: "8rem",
    },

    "& .DataTable__cell": {
      fontSize: type.textSmall.fontSize,
    },
  },

  row_total: {
    background: `${colors.white} !important`,
    borderBottom: `1px solid ${colors.grey.dark} !important`,
    ...type.textBold,
  },
};
