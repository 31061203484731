import * as React from "react";

import {
  ErrorBox,
  LoadingContainer,
  navigateTo,
} from "@interactive-investor/onestack-web-shared";

import { withAuth } from "../../contexts/Auth";
import { AuthContextInjectedProps } from "../../contexts/Auth/interfaces/context";
import { ApiError } from "../../services/api/interfaces/apiError";
import VirtualPortfolioService from "../../services/virtual-portfolio";

import VPortfolioViewNoPortfolios from "./views/noPortfolios";

import { VirtualPortfolioContainerProps } from "./interfaces/props";
import { VirtualPortfolioContainerState } from "./interfaces/state";

import { styles } from "./styles";
export type MergedProps = VirtualPortfolioContainerProps &
  AuthContextInjectedProps;

class VirtualPortfolioContainer extends React.Component<
  MergedProps,
  VirtualPortfolioContainerState
> {
  accessToken: string = this.props.auth.getAccessTokenFree() as string;
  cancellable: {
    setState: Function | undefined;
  } = {
    setState: undefined,
  };

  constructor(props: MergedProps) {
    super(props);

    // We create a reference to setState in our cancellable object
    // and use that instead of setState directly, so that we can
    // cancel setState requests if the component un-mounts
    this.cancellable.setState = this.setState.bind(this);

    this.state = {
      countPortfolios: 0,
      error: undefined,
      maxPortfolioItems: 0,
      maxPortfolios: 0,
      mounted: false,
      portfolioProcessing: true,
      portfolios: [],
    };
  }

  safeSetState = (state: any, cb?: Function) => {
    if (this.cancellable.setState) {
      this.cancellable.setState(state, cb);
    }
  };

  getPortfolioHeaders = (cb?: Function) => {
    // We always need to get the list of portfolios for the page header selector
    if (this.state.portfolios.length === 0) {
      VirtualPortfolioService.getPortfolios(this.accessToken)
        .then((response: any) => {
          this.safeSetState(
            {
              countPortfolios: response.countPortfolioHeaders || 0,
              maxPortfolioItems: response.maxPortfolioItems || 0,
              maxPortfolios: response.maxPortfolioHeaders || 0,
              portfolioProcessing: false,
              portfolios: response.results,
            },
            () => {
              if (cb) {
                cb();
              }
            }
          );
        })
        .catch((error: ApiError) => {
          this.safeSetState({ error });
        });
    }
  };

  componentDidMount() {
    this.safeSetState({ mounted: true }, () => {
      this.getPortfolioHeaders(() => {
        if (this.state.countPortfolios > 0) {
          // If we have one or more portfolios, get the items of the first one
          navigateTo(`/virtual-portfolio/${this.state.portfolios[0].id}`);
        } else {
          // We are on zero state, no more requests
          this.safeSetState({ portfolios: [], portfolioProcessing: false });
        }
      });
    });
  }

  componentWillUnmount() {
    // Make un-mounting safe, by removing reference to setState
    this.cancellable.setState = undefined;
  }

  render() {
    const { countPortfolios, portfolioProcessing, error } = this.state;

    if (typeof error !== "undefined") {
      return (
        <div className="GridRow">
          <div className="GridCell-xs-auto GridCell-lg-8">
            <ErrorBox error={error} />
          </div>
        </div>
      );
    }

    if (portfolioProcessing) {
      return (
        <LoadingContainer
          title="Virtual Portfolio"
          message="Loading Virtual Portfolio"
        />
      );
    }

    return (
      <div css={styles.container}>
        {countPortfolios === 0 && (
          <VPortfolioViewNoPortfolios containerClass={styles.no_portfolios} />
        )}
      </div>
    );
  }
}

export default withAuth(VirtualPortfolioContainer);
