import { CSSObject } from "@emotion/css";

import { type, variables } from "@interactive-investor/onestack-web-shared";

export const deletePortfolioStyles = {
  container: {
    "& p": {
      ...type.textSmall,
    },
  } as CSSObject,

  notice: {
    ...type.textSmall,
  } as CSSObject,

  button_container: {
    marginTop: variables.marginSm,
    textAlign: "center",
  } as CSSObject,
};
