import React from "react";

import { Button } from "@interactive-investor/onestack-web-shared";

import VirtualPortfolioAddItemForm from "./components/AddItemForm";
import { VPortfolioAddItemModalProps } from "./interfaces/props";

import { addItemModalStyles as styles } from "./styles/searchAndAdd";

class VPortfolioAddItemModal extends React.Component<
  VPortfolioAddItemModalProps
> {
  constructor(props: VPortfolioAddItemModalProps) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      onCloseButtonClick,
      onSuccess,
      portfolioId,
      selectedInstrument,
    } = this.props;

    return (
      <div css={styles.container}>
        <p>
          Add <strong>{selectedInstrument.name}</strong> to an existing virtual
          portfolio, or create a new one
        </p>

        <VirtualPortfolioAddItemForm
          selectedInstrument={selectedInstrument}
          portfolioId={portfolioId}
          buttonContainerClass={styles.button_container}
          onSuccess={() => onSuccess()}
        />

        <div css={styles.button_container}>
          <Button
            type="link"
            size="medium"
            onClick={() => onCloseButtonClick()}
          >
            cancel
          </Button>
        </div>
      </div>
    );
  }
}

export default VPortfolioAddItemModal;
