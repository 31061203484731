import {
  colors,
  EmotionStyles,
  type,
  variables,
} from "@interactive-investor/onestack-web-shared";

export const styles: EmotionStyles = {
  actionCopy: {
    "& h3": {
      fontWeight: type.fontWeightBold,
      marginBottom: variables.marginSm,
      marginTop: variables.marginXs,
    },
    "& h3, & p": {
      ...type.textSmall,
    },
    paddingLeft: variables.paddingMd,
  },
  actionItem: {
    display: "flex",
  },
  container: {},
  header: {
    "& h2": {
      fontWeight: type.fontWeightBold,
      marginBottom: 0,
    },

    "& span": {
      cursor: "pointer",
      display: "block",
      fontSize: 22,
      padding: `${variables.paddingXs} ${variables.paddingSm} 0 0`,
    },

    alignItems: "center",
    borderBottom: `1px solid ${colors.whiteHover}`,
    display: "flex",
    justifyContent: "space-between",
    margin: `0 -${variables.marginMd} ${variables.marginMd}`,
    padding: `0 ${variables.marginMd} ${variables.marginMd}`,
  },
  iconContainer: {
    flexBasis: 30,
    fontSize: type.baseUnit,
  },
};
