import React from "react";

import { InstrumentDetailResponse } from "@ii/onestack-shared";
import {
  ErrorBox,
  FlashMessageContext,
  GTMService,
  InstrumentService,
  LoadingContainer,
  navigateTo,
} from "@interactive-investor/onestack-web-shared";

import { ApiError } from "../../services/api/interfaces/apiError";

import VirtualPortfolioAddItemForm from "../../components/VirtualPortfolio/components/AddItemForm";
import { withAuth } from "../../contexts/Auth";
import { AuthContextInjectedProps } from "../../contexts/Auth/interfaces/context";

import { VirtualPortfolioAddInstrumentContainerProps } from "./interfaces/props";
import { VirtualPortfolioAddInstrumentContainerState } from "./interfaces/state";

import { styles } from "./styles";
export type MergedProps = VirtualPortfolioAddInstrumentContainerProps &
  AuthContextInjectedProps;

class VirtualPortfolioAddInstrumentContainer extends React.Component<
  MergedProps,
  VirtualPortfolioAddInstrumentContainerState
> {
  accessToken: string = this.props.auth.getAccessTokenFree() as string;

  constructor(props: MergedProps) {
    super(props);

    this.state = {
      error: undefined,
      mounted: false,
      processing: true,
    };
  }

  componentDidMount() {
    const { friendlyInstrumentType, slug, friendlyInstrumentId } = this.props;

    this.setState({ mounted: true });

    InstrumentService.getDetail(
      friendlyInstrumentType,
      slug,
      friendlyInstrumentId
    )
      .then((instrument: InstrumentDetailResponse) => {
        this.setState({
          instrument,
          processing: false,
        });

        GTMService.pushPageView(
          `Add to virtual portfolio - ${instrument.name} (${friendlyInstrumentId})`,
          `/virtual-portfolio/add/${friendlyInstrumentType}/${slug}/${friendlyInstrumentId}`,
          "Page"
        );
      })
      .catch((error: ApiError) => {
        this.setState({ error });
      });
  }

  render() {
    const { error, processing, instrument } = this.state;

    if (typeof error !== "undefined") {
      return (
        <div className="GridRow" css={styles.container}>
          <div className="GridCell-xs-auto GridCell-lg-8">
            <ErrorBox error={error} />
          </div>
        </div>
      );
    }

    if (processing || !instrument) {
      return (
        <LoadingContainer
          title="Add to Virtual Portfolio"
          message="Loading Add to Virtual Portfolio"
        />
      );
    }

    return (
      <FlashMessageContext.Consumer>
        {(flashMessage) => (
          <div className="GridRow" css={styles.container}>
            <div className="GridCell-xs-auto GridCell-lg-6 GridCell-offset-lg-3">
              <h1>Add to virtual portfolio</h1>

              <p>
                Add <strong>{instrument.name}</strong> to an existing virtual
                portfolio, or create a new one
              </p>

              <VirtualPortfolioAddItemForm
                selectedInstrument={instrument}
                onSuccess={(portfolioId?: string) => {
                  flashMessage.setMessage({
                    message: `${instrument.name} added to virtual portfolio`,
                    type: "success",
                  });

                  if (portfolioId) {
                    navigateTo(`/virtual-portfolio/${portfolioId}`);
                  } else {
                    navigateTo(`/virtual-portfolio`);
                  }
                }}
              />
            </div>
          </div>
        )}
      </FlashMessageContext.Consumer>
    );
  }
}

export default withAuth(VirtualPortfolioAddInstrumentContainer);
