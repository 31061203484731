import { CSSObject } from "@emotion/css";

import {
  grid,
  type,
  variables,
} from "@interactive-investor/onestack-web-shared";

export const searchAndAddStyles = {
  container: {} as CSSObject,

  header: {
    "& h2": {
      ...type.textLowercase,
    },
  } as CSSObject,

  instrument_search: {
    fontSize: type.baseUnit,

    [grid.breakpointMixins.sm]: {
      fontSize: type.baseUnitMd,
    },

    [grid.breakpointMixins.md]: {
      fontSize: type.textParagraphLarge.fontSize,
    },
  } as CSSObject,

  button: {
    marginTop: variables.marginLg,
  } as CSSObject,
};

export const addItemModalStyles = {
  container: {
    "& p": {
      ...type.textSmall,
    },
  } as CSSObject,

  button_container: {
    textAlign: "center",
  } as CSSObject,
};
