import { CSSObject } from "@emotion/core";
import React, { useState } from "react";
import { ApiDataErrorItem } from "../../../../services/api/interfaces/apiError";

import { Icon, TextArea } from "@interactive-investor/onestack-web-shared";

interface NotesProps {
  containerClass?: CSSObject;
  onItemChange: (notes: string) => void;
  error?: ApiDataErrorItem;
  processing: boolean;
  notes: string;
  notesRequired: boolean;
}

export const Notes: React.FC<NotesProps> = ({
  containerClass,
  onItemChange,
  error,
  processing,
  notes,
  notesRequired,
}) => {
  const [inputValue, setInputValue] = useState(notes);
  return (
    <TextArea
      cssStyles={containerClass ? [containerClass] : [{}]}
      placeholder="Write a note..."
      name="notes"
      size="auto"
      value={inputValue}
      onChange={(n: string) => {
        onItemChange(n);
        setInputValue(n);
      }}
      optional={!notesRequired}
      readOnly={processing}
      maxlength={4000}
      error={error ? error.message : undefined}
      rows={4}
      description={
        <React.Fragment>
          <Icon set="fal" identifier="info-circle" /> Do not include any private
          or confidential information in your notes
        </React.Fragment>
      }
    />
  );
};

export default Notes;
