import { CSSObject } from "@emotion/css";

import {
  colors,
  grid,
  type,
  variables,
} from "@interactive-investor/onestack-web-shared";

export const itemFormStyles = {
  container: {
    marginBottom: variables.marginLg,
  },

  radio_container: {
    display: "flex",
    lineHeight: "1",
    marginTop: `-${variables.marginSm}`,

    "& > div, & > div > div": {
      marginBottom: 0,
    },

    "& > div:first-of-type": {
      paddingRight: variables.paddingSm,
    },

    "& > div:last-of-type": {
      paddingLeft: variables.paddingSm,
    },

    "& > div input": {
      width: "100%",
    },

    "& > div input[readonly], & > div input[readonly]:hover": {
      borderColor: "transparent",
    },

    "& .RadioButton": {
      display: "block",
      marginBottom: variables.marginMd,
      cursor: "pointer",
    },
  } as CSSObject,

  date_container: {
    marginTop: variables.marginLg,

    "& legend": {
      ...type.textH4,
    },
  } as CSSObject,

  date_container_inputs: {
    display: "flex",
    marginBottom: 0,

    "& > div": {
      flex: 1,
      marginRight: variables.marginMd,
    },

    "& > div:last-child": {
      marginRight: 0,
    },

    "& > div input": {
      width: "100%",
    },
  } as CSSObject,

  dateInput: {
    marginTop: 0,
  },

  date_container_inputs_error: {
    ...type.textSmall,
    color: colors.accent3,
  } as CSSObject,

  edit_form_row: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",

    [grid.breakpointMixins.lg]: {
      flexDirection: "row",
      flexWrap: "wrap",
    },

    "& > div": {
      marginRight: variables.marginMd,
    },

    "& input": {
      ...type.textParagraph,
    },
  } as CSSObject,

  edit_form_units: {
    [grid.breakpointMixins.md]: {
      marginTop: 18,
    },

    "& span": {
      fontSize: type.textSmall.fontSize,
      lineHeight: 1.45,
    },
  } as CSSObject,

  notes: {
    "& textarea": {
      ...type.textParagraph,
      width: "100%",
    },
  } as CSSObject,

  edit_purchase_dates: {
    width: "100%",
    flexBasis: "100%",
  },

  get edit_radio_container() {
    return {
      ...this.radio_container,

      "& .RadioButton": {
        lineHeight: 1,
        marginBottom: variables.marginSm,
      },

      "& .RadioButton .Checkbox__icon": {
        fontSize: "1rem",
      },

      "& .RadioButton > span": {
        ...type.textSmall,
        color: colors.black.secondary,
        lineHeight: 1,
      },
    } as CSSObject;
  },

  get edit_form_date_container() {
    return {
      ...this.date_container,

      [grid.breakpointMixins.lg]: {
        width: "370px",
        flexBasis: "370px",
      },

      "& legend": {
        ...type.textH4,
      },
    } as CSSObject;
  },
};
