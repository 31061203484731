import {
  colors,
  EmotionStyles,
  variables,
} from "@interactive-investor/onestack-web-shared";

export const styles: EmotionStyles = {
  container: {
    minHeight: "50vh",
  },

  no_portfolios: {
    marginTop: variables.marginXxl,
  },

  no_portfolio_items: {
    marginTop: variables.marginXxl,
  },

  no_portfolio_items_button_container: {
    marginTop: variables.marginLg,
    textAlign: "center",
  },

  portfolio_items: {
    marginBottom: variables.marginMd,
  },

  edit_view: {},

  unsupported: {
    backgroundColor: colors.lightRed,
    padding: variables.paddingSm,
    marginTop: variables.marginMd,
    boxShadow: `0 1px 5px 0 rgba(99, 109, 117, 0.25)`,
  },
};
