import React from "react";
import { Helmet } from "react-helmet";

import {
  ErrorBox,
  GTMService,
  LoadingContainer,
  mergeDeepImmutable,
  Modal,
  navigateTo,
  Pagination,
} from "@interactive-investor/onestack-web-shared";
import { ListedInstrumentSearchResult } from "@ii/onestack-shared";

import { withAuth } from "../../contexts/Auth";
import { AuthContextInjectedProps } from "../../contexts/Auth/interfaces/context";
import { ApiError } from "../../services/api/interfaces/apiError";
import VirtualPortfolioService from "../../services/virtual-portfolio";
import {
  VirtualPortfolioInterface,
  VirtualPortfolioWithItemsInterface,
} from "../../services/virtual-portfolio/interfaces/virtualPortfolio";

import VPortfolioAddItemModal from "../../components/VirtualPortfolio/AddItemModal";
import VPortfolioCreatePortfolioModal from "../../components/VirtualPortfolio/CreatePortfolioModal";
import VPDeletePortfolioItemModal from "../../components/VirtualPortfolio/DeletePortfolioItemModal";
import VPDeletePortfolioModal from "../../components/VirtualPortfolio/DeletePortfolioModal";
import VPortfolioHeader from "../../components/VirtualPortfolio/Header";
import VPortfolioSearchAndAddModal from "../../components/VirtualPortfolio/SearchAndAddModal";

import VPortfolioViewEditView from "./views/editView";
import VPortfolioViewNoPortfolioItems from "./views/noPortfolioItems";
import VPortfolioViewNoPortfolios from "./views/noPortfolios";
import VPortfolioViewPortfolioItems from "./views/portfolioItems";

import { VirtualPortfolioPageContainerProps } from "./interfaces/props";
import { VirtualPortfolioPageContainerState } from "./interfaces/state";

import { styles } from "./styles";

export type MergedProps = VirtualPortfolioPageContainerProps &
  AuthContextInjectedProps;

class VirtualPortfolioPageContainer extends React.Component<
  MergedProps,
  VirtualPortfolioPageContainerState
> {
  accessToken: string = this.props.auth.getAccessTokenFree() as string;
  cancellable: {
    setState: Function | undefined;
  } = {
    setState: undefined,
  };

  constructor(props: MergedProps) {
    super(props);

    // We create a reference to setState in our cancellable object
    // and use that instead of setState directly, so that we can
    // cancel setState requests if the component un-mounts
    this.cancellable.setState = this.setState.bind(this);

    this.state = {
      actionsHelpModalShow: false,
      addItemModalShow: false,
      addItemSelectedInstrument: undefined,
      countPortfolios: 0,
      createPortfolioModalShow: false,
      deletePortfolioItemModalShow: false,
      deletePortfolioModalShow: false,
      editPortfolioNameError: undefined,
      editPortfolioNameProcessing: false,
      editPortfolioNameSuccess: false,
      editView: false,
      error: undefined,
      maxPortfolioItems: 0,
      maxPortfolios: 0,
      mounted: false,
      page: props.pageNumber || 1,
      portfolio: undefined,
      portfolioItemTypeFilter: "ALL",
      portfolioItemsProcessing: false,
      portfolioProcessing: true,
      portfolios: [],
      searchAndAddModalShow: false,
    };
  }

  safeSetState = (state: any, cb?: Function) => {
    if (this.cancellable.setState) {
      this.cancellable.setState(state, cb);
    }
  };

  handlePortfoliosItemsPage = (portfolioId: string, page: number) => {
    if (!this.state.portfolioItemsProcessing) {
      this.safeSetState({
        addItemModalShow: false,
        createPortfolioModalShow: false,
        portfolioItemsProcessing: true,
        searchAndAddModalShow: false,
      });

      // Update headers to catch any changes
      this.getPortfolioHeaders();

      // Get portfolio and items
      VirtualPortfolioService.getPortfolioItems(
        portfolioId,
        this.state.portfolioItemTypeFilter,
        page || 1,
        this.accessToken
      )
        .then((response: any) => {
          const portfolio: VirtualPortfolioWithItemsInterface = {
            countPortfolioItems: response.countPortfolioItems,
            id: response.header.id,
            items: response.results,
            maxPortfolioItems: response.maxPortfolioItems,
            name: response.header.name,
            pagination: response.pagination,
            totals: response.totals,
          };

          const portfolioPath =
            page === 1
              ? `/virtual-portfolio/${portfolio.id}`
              : `/virtual-portfolio/${portfolio.id}/page/${page}`;
          GTMService.pushPageView(
            `Virtual Portfolio - ${portfolio.name}`,
            portfolioPath,
            "Page"
          );

          this.safeSetState({
            portfolio,
            portfolioItemsProcessing: false,
          });
        })
        .catch((error: ApiError) => {
          this.safeSetState({ error });
        });
    }
  };

  handleAddInstrument = (
    addItemSelectedInstrument: ListedInstrumentSearchResult
  ) => {
    this.safeSetState({ addItemSelectedInstrument }, () => {
      this.toggleAddItemModal();
    });
  };

  toggleSearchAndAddModal = (show?: boolean) => {
    this.safeSetState({
      searchAndAddModalShow:
        typeof show !== "undefined" ? show : !this.state.searchAndAddModalShow,
    });
  };

  toggleCreatePortfolioModal = (show?: boolean) => {
    this.safeSetState({
      createPortfolioModalShow:
        typeof show !== "undefined"
          ? show
          : !this.state.createPortfolioModalShow,
    });
  };

  toggleAddItemModal = (show?: boolean) => {
    this.safeSetState({
      addItemModalShow:
        typeof show !== "undefined" ? show : !this.state.addItemModalShow,
    });
  };

  toggleDeletePortfolioModal = (show?: boolean) => {
    this.safeSetState({
      deletePortfolioModalShow:
        typeof show !== "undefined"
          ? show
          : !this.state.deletePortfolioModalShow,
    });
  };

  toggleDeletePortfolioItemModal = (
    portfolioItemId: string | undefined,
    show?: boolean
  ) => {
    this.safeSetState({
      deletePortfolioItemModalItem: portfolioItemId,
      deletePortfolioItemModalShow:
        typeof show !== "undefined"
          ? show
          : !this.state.deletePortfolioItemModalShow,
    });
  };

  toggleEditView = (editViewActiveItemId?: string) => {
    window.scrollTo(0, 0);
    this.safeSetState({
      addItemModalShow: false,
      addItemSelectedInstrument: undefined,
      createPortfolioModalShow: false,
      editView: !this.state.editView,
      editViewActiveItemId:
        !this.state.editView && editViewActiveItemId
          ? editViewActiveItemId
          : undefined,
      searchAndAddModalShow: false,
    });
  };

  onDeletePortfolio = () => {
    if (this.state.portfolio && this.state.portfolio.id) {
      VirtualPortfolioService.deletePortfolio(
        this.state.portfolio.id,
        this.accessToken
      )
        .then(() => {
          return this.safeSetState({ deletePortfolioModalShow: false }, () =>
            navigateTo("/virtual-portfolio")
          );
        })
        .catch((error: ApiError) => {
          this.safeSetState({ error });
        });
    }
  };

  onDeletePortfolioItem = (portfolioItemId: string) => {
    if (this.state.portfolio && this.state.portfolio.id) {
      const portfolioId = this.state.portfolio.id;
      VirtualPortfolioService.deletePortfolioItem(
        portfolioId,
        portfolioItemId,
        this.accessToken
      )
        .then((response: any) => {
          this.safeSetState({ deletePortfolioItemModalShow: false }, () =>
            this.handlePortfoliosItemsPage(portfolioId, 1)
          );
        })
        .catch((error: ApiError) => {
          this.safeSetState({ error });
        });
    }
  };

  onUpdatePortfolioItemSuccess = () => {
    if (this.state.portfolio && this.state.portfolio.id) {
      const portfolioId = this.state.portfolio.id;
      this.handlePortfoliosItemsPage(portfolioId, 1);
    }
  };

  onPortfolioNameChange = (newName: string) => {
    if (!this.state.portfolio) {
      return;
    }

    this.safeSetState({
      editPortfolioNameError: undefined,
      editPortfolioNameProcessing: true,
      editPortfolioNameSuccess: false,
    });

    VirtualPortfolioService.updatePortfolio(
      this.state.portfolio.id,
      newName,
      this.accessToken
    )
      .then((response: any) => {
        this.safeSetState({
          editPortfolioNameError: undefined,
          editPortfolioNameProcessing: false,
          editPortfolioNameSuccess: true,
          portfolio: mergeDeepImmutable(this.state.portfolio as object, {
            name: newName,
          }) as VirtualPortfolioWithItemsInterface,
          portfolios: this.state.portfolios.map(
            (p: VirtualPortfolioInterface) => {
              return this.state.portfolio && p.id === this.state.portfolio.id
                ? {
                    ...p,
                    name: newName,
                  }
                : p;
            }
          ),
        });
      })
      .catch((error: ApiError) => {
        const { errors } = error.data;

        const nameError = errors
          ? errors.find((err: any) => err.field === "name")
          : undefined;

        this.safeSetState({
          editPortfolioNameError: nameError ? nameError.message : error.message,
          editPortfolioNameProcessing: false,
        });
      });
  };

  onFilterPortfolioItemType = (
    portfolioId: string,
    portfolioItemTypeConstant: string
  ) => {
    this.safeSetState(
      {
        page: 1,
        portfolioItemTypeFilter: portfolioItemTypeConstant,
      },
      () => {
        this.handlePortfoliosItemsPage(portfolioId, 1);
      }
    );
  };

  getPortfolioHeaders = (cb?: Function) => {
    // We always need to get the list of portfolios for the page header selector
    VirtualPortfolioService.getPortfolios(this.accessToken)
      .then((response: any) => {
        this.safeSetState(
          {
            countPortfolios: response.countPortfolioHeaders || 0,
            maxPortfolioItems: response.maxPortfolioItems || 0,
            maxPortfolios: response.maxPortfolioHeaders || 0,
            portfolioProcessing: false,
            portfolios: response.results,
          },
          () => {
            if (cb) {
              cb();
            }
          }
        );
      })
      .catch((error: ApiError) => {
        this.safeSetState({ error });
      });
  };

  componentDidMount() {
    const { portfolioId, pageNumber } = this.props;

    if (portfolioId) {
      this.handlePortfoliosItemsPage(portfolioId, pageNumber || 1);
    }

    this.safeSetState({ mounted: true });
  }

  componentWillUnmount() {
    // Make un-mounting safe, by removing reference to setState
    this.cancellable.setState = undefined;
  }

  componentDidUpdate(prevProps: MergedProps) {
    const { portfolioId, pageNumber } = this.props;

    // If we have a portfolioId in props (passed from @reach/router)
    if (portfolioId) {
      // We're loading for the first time or we have changed portfolio
      if (portfolioId !== prevProps.portfolioId) {
        this.handlePortfoliosItemsPage(portfolioId, 1);
      }

      // We've changed page number
      if (pageNumber && pageNumber !== prevProps.pageNumber) {
        this.safeSetState({ page: pageNumber }, () => {
          this.handlePortfoliosItemsPage(portfolioId, pageNumber);
        });
      }
    }
  }

  goToPage(page: number) {
    if (this.state.portfolio) {
      navigateTo(`/virtual-portfolio/${this.state.portfolio.id}/page/${page}`);
    }
  }

  getNumberOfPages() {
    const { portfolio } = this.state;
    if (portfolio) {
      return Math.ceil(
        portfolio.pagination.totalItems / portfolio.pagination.perPage
      );
    } else {
      return 0;
    }
  }

  goToNextPage() {
    const { page } = this.state;
    if (page < this.getNumberOfPages() && this.state.portfolio) {
      navigateTo(
        `/virtual-portfolio/${this.state.portfolio.id}/page/${page + 1}`
      );
    }
  }

  goToPreviousPage() {
    const { page } = this.state;
    if (page > 1 && this.state.portfolio) {
      navigateTo(
        `/virtual-portfolio/${this.state.portfolio.id}/page/${page - 1}`
      );
    }
  }

  render() {
    const {
      portfolios,
      countPortfolios,
      maxPortfolios,
      portfolio,
      portfolioProcessing,
      portfolioItemsProcessing,
      portfolioItemTypeFilter,
      createPortfolioModalShow,
      searchAndAddModalShow,
      addItemModalShow,
      addItemSelectedInstrument,
      deletePortfolioModalShow,
      deletePortfolioItemModalShow,
      deletePortfolioItemModalItem,
      editView,
      editViewActiveItemId,
      error,
      editPortfolioNameProcessing,
      editPortfolioNameSuccess,
      editPortfolioNameError,
    } = this.state;

    if (typeof error !== "undefined") {
      return (
        <div className="GridRow">
          <div className="GridCell-xs-auto GridCell-lg-8">
            <ErrorBox error={error} />
          </div>
        </div>
      );
    }

    if (portfolioProcessing) {
      return (
        <LoadingContainer
          title="Virtual Portfolio"
          message="Loading Virtual Portfolio"
        />
      );
    }

    let view = "";
    if (portfolios.length === 0) {
      view = "no-portfolios";
    } else if (
      !portfolioItemsProcessing &&
      portfolio &&
      portfolio.items.length === 0 &&
      portfolioItemTypeFilter === "ALL"
    ) {
      view = "no-portfolio-items";
    } else if (
      !portfolioItemsProcessing &&
      portfolio &&
      ((portfolio.items.length > 0 && portfolioItemTypeFilter === "ALL") ||
        portfolioItemTypeFilter !== "ALL") &&
      !editView
    ) {
      view = "portfolio-items";
    } else if (
      !portfolioItemsProcessing &&
      portfolio &&
      portfolio.items.length > 0 &&
      editView
    ) {
      view = "edit-view";
    }
    return (
      <div css={styles.container}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title={`${portfolio ? `${portfolio.name} - ` : ""}Virtual Portfolio`}
        />

        <VPortfolioHeader
          portfolios={portfolios}
          countPortfolios={countPortfolios}
          isCreateVPModalOpen={createPortfolioModalShow}
          isDeleteVPModalOpen={deletePortfolioModalShow}
          isSearchVPModalOpen={searchAndAddModalShow}
          maxPortfolios={maxPortfolios}
          portfolio={portfolio}
          portfolioItemsProcessing={portfolioItemsProcessing}
          portfolioItemTypeFilter={portfolioItemTypeFilter}
          onCreatePortfolioButtonClick={() => this.toggleCreatePortfolioModal()}
          onSearchAndAddButtonClick={() => this.toggleSearchAndAddModal()}
          onDeletePortfolioButtonClick={() => this.toggleDeletePortfolioModal()}
          onEditViewButtonClick={() => this.toggleEditView()}
          editView={editView}
          editPortfolioNameProcessing={editPortfolioNameProcessing}
          editPortfolioNameSuccess={editPortfolioNameSuccess}
          editPortfolioNameError={editPortfolioNameError}
          onSavePortfolioNameButtonClick={(newName: string) =>
            this.onPortfolioNameChange(newName)
          }
        />

        {view === "no-portfolios" && (
          <VPortfolioViewNoPortfolios containerClass={styles.no_portfolios} />
        )}

        {view === "no-portfolio-items" && (
          <VPortfolioViewNoPortfolioItems
            containerClass={styles.no_portfolio_items}
            buttonContainerClass={styles.no_portfolio_items_button_container}
            onSearchAndAddButtonClick={() => this.toggleSearchAndAddModal()}
          />
        )}

        {view === "portfolio-items" && (
          <React.Fragment>
            <VPortfolioViewPortfolioItems
              containerClass={styles.portfolio_items}
              portfolio={portfolio as VirtualPortfolioWithItemsInterface}
              onDeletePortfolioItem={(portfolioItemId: string) =>
                this.toggleDeletePortfolioItemModal(portfolioItemId)
              }
              onEditPortfolioItem={(portfolioItemId: string) =>
                this.toggleEditView(portfolioItemId)
              }
              onFilterPortfolioItemType={(portfolioItemTypeConstant: string) =>
                this.onFilterPortfolioItemType(
                  (portfolio as VirtualPortfolioWithItemsInterface).id,
                  portfolioItemTypeConstant
                )
              }
              portfolioItemTypeFilter={portfolioItemTypeFilter}
            />

            {this.getNumberOfPages() > 1 && (
              <div className="GridRow">
                <div className="GridCell-xs-12">
                  <Pagination
                    type="dynamic"
                    totalPages={this.getNumberOfPages()}
                    pageNumber={this.state.page}
                    onPageClick={(pageNumber: number) =>
                      this.goToPage(pageNumber)
                    }
                    onPrevPageClick={() => this.goToPreviousPage()}
                    onNextPageClick={() => this.goToNextPage()}
                  />
                </div>
              </div>
            )}

            {(portfolio as VirtualPortfolioWithItemsInterface).items.filter(
              (item) => item.status === "UNSUPPORTED"
            ).length > 0 && (
              <div className="GridRow">
                <div className="GridCell-xs-12 GridCell-lg-8">
                  <div css={styles.unsupported}>
                    * Holdings that are red shaded do not have a price as they
                    are not supported in the new virtual portfolio. If the
                    holding is a fund then you may be able to find an
                    alternative share class of that fund using search &amp; add.
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        )}

        {view === "edit-view" && (
          <VPortfolioViewEditView
            containerClass={styles.edit_view}
            portfolio={portfolio as VirtualPortfolioWithItemsInterface}
            onDeletePortfolioItem={(portfolioItemId: string) =>
              this.toggleDeletePortfolioItemModal(portfolioItemId)
            }
            editViewActiveItemId={editViewActiveItemId}
            onUpdatePortfolioItemSuccess={() =>
              this.onUpdatePortfolioItemSuccess()
            }
          />
        )}

        <Modal
          id="vportfolio-create-modal"
          modalHeader={true}
          modalHeaderTitle="Create a new virtual portfolio"
          onClose={() => this.toggleCreatePortfolioModal()}
          show={createPortfolioModalShow}
          size="medium"
        >
          <VPortfolioCreatePortfolioModal
            countPortfolios={countPortfolios}
            isModalOpen={this.state.createPortfolioModalShow}
            maxPortfolios={maxPortfolios}
            onCloseButtonClick={() => this.toggleCreatePortfolioModal()}
          />
        </Modal>

        <Modal
          show={searchAndAddModalShow}
          id="vportfolio-search-and-add-modal"
          onClose={() => this.toggleSearchAndAddModal()}
          modalHeader={false}
          size="full"
        >
          <VPortfolioSearchAndAddModal
            onInstrumentSelect={(instrument) =>
              this.handleAddInstrument(instrument)
            }
            onCloseButtonClick={() => this.toggleSearchAndAddModal()}
          />
        </Modal>

        <Modal
          show={addItemModalShow}
          id="vportfolio-add-item-modal"
          size="medium"
          boxShadow={true}
          modalHeader={true}
          modalHeaderTitle="Add to a virtual portfolio"
          onClose={() => {
            this.toggleAddItemModal();
          }}
        >
          {addItemSelectedInstrument && portfolio && (
            <VPortfolioAddItemModal
              selectedInstrument={addItemSelectedInstrument}
              onSuccess={() => this.handlePortfoliosItemsPage(portfolio.id, 1)}
              onCloseButtonClick={() => {
                this.toggleAddItemModal();
              }}
              portfolioId={portfolio.id}
              isModalOpen={this.state.addItemModalShow}
            />
          )}
        </Modal>

        <Modal
          id="vportfolio-delete"
          modalHeader={true}
          modalHeaderTitle="Delete virtual portfolio"
          onClose={() => this.toggleDeletePortfolioModal()}
          show={deletePortfolioModalShow}
          size="small"
        >
          {!portfolioItemsProcessing && portfolio && (
            <VPDeletePortfolioModal
              onCloseButtonClick={() => this.toggleDeletePortfolioModal()}
              onDeletePortfolio={() => this.onDeletePortfolio()}
              isModalOpen={this.state.deletePortfolioModalShow}
            />
          )}
        </Modal>

        <Modal
          id="vportfolio-delete-item"
          modalHeader={true}
          modalHeaderTitle="Delete Row"
          onClose={(portfolioItemId: string | undefined) =>
            this.toggleDeletePortfolioItemModal(portfolioItemId)
          }
          show={deletePortfolioItemModalShow}
          size="small"
        >
          {!portfolioItemsProcessing &&
            portfolio &&
            portfolio.items.length > 0 &&
            deletePortfolioItemModalItem && (
              <VPDeletePortfolioItemModal
                portfolioItemId={deletePortfolioItemModalItem}
                onCloseButtonClick={(portfolioItemId: string) =>
                  this.toggleDeletePortfolioItemModal(portfolioItemId)
                }
                onDeletePortfolioItem={(portfolioItemId: string) =>
                  this.onDeletePortfolioItem(portfolioItemId)
                }
                isModalOpen={this.state.deletePortfolioItemModalShow}
              />
            )}
        </Modal>
      </div>
    );
  }
}

export default withAuth(VirtualPortfolioPageContainer);
