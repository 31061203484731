import React from "react";

import {
  Button,
  InstrumentSearch,
} from "@interactive-investor/onestack-web-shared";
import { ListedInstrumentSearchResult } from "@ii/onestack-shared";
import { VPortfolioSearchAndAddModalProps } from "./interfaces/props";

import { searchAndAddStyles as styles } from "./styles/searchAndAdd";

class VPortfolioSearchAndAddModal extends React.Component<
  VPortfolioSearchAndAddModalProps
> {
  render() {
    const { onInstrumentSelect, onCloseButtonClick } = this.props;

    return (
      <div css={styles.container}>
        <div className="GridRow" css={styles.header}>
          <div className="GridCell-xs-grow">
            <h2>Add to Virtual Portfolio</h2>
          </div>
          <div className="GridCell-xs-shrink">
            <Button
              type="secondary"
              size="medium"
              onClick={() => onCloseButtonClick()}
              leftIcon="times"
              leftIconSet="fal"
            >
              Close
            </Button>
          </div>
        </div>

        <div className="GridRow">
          <div className="GridCell-xs-auto GridCell-lg-10 GridCell-offset-lg-1">
            <InstrumentSearch
              focusQueryOnLoad={true}
              selectedInstrumentSelectCallback={(
                selectedInstrument: ListedInstrumentSearchResult
              ) => onInstrumentSelect(selectedInstrument)}
              cssStyles={[styles.instrument_search]}
              instrumentBaseUrl={process.env.GATSBY_MAIN_SITE_URL}
              instrumentBaseUrlIsExternal={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default VPortfolioSearchAndAddModal;
