import * as React from "react";

import VirtualPortfolioItemsTable from "../../../components/VirtualPortfolio/ItemsTable";

import { VPortfolioViewPortfolioItemsProps } from "../interfaces/props";

const VPortfolioViewPortfolioItems = ({
  containerClass,
  portfolio,
  onDeletePortfolioItem,
  onEditPortfolioItem,
  onFilterPortfolioItemType,
  portfolioItemTypeFilter,
}: VPortfolioViewPortfolioItemsProps) => (
  <div className={`${containerClass || ""} GridRow`}>
    <div className="GridCell-xs-auto">
      <VirtualPortfolioItemsTable
        portfolio={portfolio}
        onDeletePortfolioItem={(portfolioItemId: string) =>
          onDeletePortfolioItem(portfolioItemId)
        }
        onEditPortfolioItem={(portfolioItemId: string) =>
          onEditPortfolioItem(portfolioItemId)
        }
        onFilterPortfolioItemType={(portfolioItemTypeConstant: string) =>
          onFilterPortfolioItemType(portfolioItemTypeConstant)
        }
        portfolioItemTypeFilter={portfolioItemTypeFilter}
      />
    </div>
  </div>
);

export default VPortfolioViewPortfolioItems;
