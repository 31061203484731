import moment from "moment";
import React from "react";

import { InstrumentDetailResponse } from "@ii/onestack-shared";
import {
  Button,
  ButtonTypeEnum,
  Checkbox,
  defaultDecimalPlaces,
  Input,
  InstrumentService,
  Select,
} from "@interactive-investor/onestack-web-shared";

import { withAuth } from "../../../contexts/Auth";
import { AuthContextInjectedProps } from "../../../contexts/Auth/interfaces/context";
import {
  ApiDataError,
  ApiError,
} from "../../../services/api/interfaces/apiError";
import VirtualPortfolioService from "../../../services/virtual-portfolio";

import Notes from "./formElements/Notes";
import PurchaseDate from "./formElements/PurchaseDate";
import { handlePriceUpdateItemState } from "./functions/itemPrice";
import RadioWithInput from "./RadioWithInput";

import { VirtualPortfolioAddItemFormProps } from "../interfaces/props";
import { VirtualPortfolioAddItemFormState } from "../interfaces/state";

import { itemFormStyles as styles } from "../styles/itemForm";

export type WithAuthProps = VirtualPortfolioAddItemFormProps &
  AuthContextInjectedProps;

class VirtualPortfolioAddItemForm extends React.Component<
  WithAuthProps,
  VirtualPortfolioAddItemFormState
> {
  accessToken = this.props.auth.getAccessTokenFree() as string;

  constructor(props: WithAuthProps) {
    super(props);

    const today = moment();

    this.state = {
      error: undefined,
      instrumentProcessing: true,
      item: {
        addNotes: false,
        instrumentType: "",
        name: "",
        notes: "",
        price: {
          currency: undefined,
        },
        purchaseDate: {
          day: today.date(),
          month: today.month() + 1,
          year: today.year(),
        },
        purchasePrice: 0,
        sedol: "",
        units: 1,
      },
      maxPortfolios: 0,
      newPortfolio: false,
      newPortfolioName: undefined,
      perShareCostActive: true, // for value, item.purchasePrice is perShareCost from the UI
      portfolioId: this.props.portfolioId,
      portfolios: [],
      portfoliosProcessing: true,
      totalCost: 0,
      totalCostActive: false,
    };
  }

  onItemChange = (key: string, value: string | number | boolean) => {
    this.setState({
      item: {
        ...this.state.item,
        [key]: value,
      },
    });
  };

  onItemDateChange = (key: string, value: number) => {
    this.setState({
      item: {
        ...this.state.item,
        purchaseDate: {
          ...this.state.item.purchaseDate,
          [key]: value,
        },
      },
    });
  };

  onItemPriceChange = (key: string, value: number) => {
    this.setState(
      handlePriceUpdateItemState({
        item: this.state.item,
        key,
        perShareCostActive: this.state.perShareCostActive,
        purchaseCurrency: this.state.item.price.currency,
        totalCost: this.state.totalCost,
        totalCostActive: this.state.totalCostActive,
        val: value,
      })
    );
  };

  onSubmit = () => {
    const { item } = this.state;

    if (this.state.portfoliosProcessing !== true) {
      this.setState({
        portfoliosProcessing: true,
      });

      const paddedMonth = String(item.purchaseDate.month).padStart(2, "0");
      const paddedDay = String(item.purchaseDate.day).padStart(2, "0");

      const purchasedDate = moment(
        `${item.purchaseDate.year}-${paddedMonth}-${paddedDay}`,
        "YYYY-MM-DD",
        true
      );

      if (!purchasedDate.isValid()) {
        this.setState({
          error: {
            errors: [
              {
                code: "INVALID",
                field: "purchaseDate",
                message: "Please enter a valid date",
              },
            ],
            status: 400,
          },
          portfoliosProcessing: false,
        });
        return;
      }

      if (this.state.newPortfolio && this.state.newPortfolioName) {
        VirtualPortfolioService.createPortfolioWithItem(
          {
            instrumentType: item.instrumentType,
            name: item.name,
            newPortfolioName: this.state.newPortfolioName,
            notes: item.notes,
            purchaseDate: purchasedDate.format("YYYY-MM-DD"),
            purchasePrice: item.purchasePrice,
            sedol: item.sedol,
            units: item.units,
          },
          this.accessToken
        )
          .then((response: any) => {
            this.props.onSuccess(response.virtualPortfolioId || undefined);
            return;
          })
          .catch((error: ApiError) => {
            console.error(error, error.data);
            this.setState({ error: error.data as ApiDataError });
          });
      } else {
        if (this.state.portfolioId) {
          VirtualPortfolioService.createPortfolioItem(
            this.state.portfolioId,
            {
              instrumentType: item.instrumentType,
              name: item.name,
              notes: item.notes,
              purchaseDate: purchasedDate.format("YYYY-MM-DD"),
              purchasePrice: item.purchasePrice,
              sedol: item.sedol,
              units: item.units,
            },
            this.accessToken
          )
            .then((response: any) => {
              this.props.onSuccess(response.virtualPortfolioId || undefined);
              return;
            })
            .catch((error: ApiError) => {
              console.error(error, error.data);
              this.setState({ error: error.data as ApiDataError });
            });
        } else {
          this.setState({
            error: {
              errors: [
                {
                  code: "INVALID",
                  field: "portfolioId",
                  message:
                    "Please choose an existing virtual portfolio or create a new one",
                },
              ],
              status: 400,
            },
            portfoliosProcessing: false,
          });
          return;
        }
      }
    }
  };

  componentDidMount() {
    const { selectedInstrument } = this.props;

    VirtualPortfolioService.getPortfolios(this.accessToken)
      .then((response: any) => {
        this.setState({
          maxPortfolios: response.maxPortfolioHeaders,
          portfolios: response.results,
          portfoliosProcessing: false,
        });
      })
      .catch((error: ApiError) => {
        console.error(error.data);
        this.setState({ error: error.data as ApiDataError });
      });

    InstrumentService.getDetail(
      selectedInstrument.friendlyInstrumentType,
      selectedInstrument.slug,
      selectedInstrument.friendlyInstrumentId
    )
      .then((instrument: InstrumentDetailResponse) => {
        // Get a default purchasePrice from instrument
        let purchasePrice: number = 0;
        if (instrument.instrumentType === "FUND") {
          if (instrument.price && instrument.price.bidAskPrice) {
            purchasePrice = instrument.price.bidAskPrice.ask || 0;
          }
          if (instrument.price && instrument.price.price) {
            purchasePrice = instrument.price.price || 0;
          }
        } else {
          purchasePrice =
            instrument.price && instrument.price.bidAskPrice
              ? instrument.price.bidAskPrice.ask
              : 0;
        }

        // Spread item with instrument values
        const item = {
          ...this.state.item,
          instrumentType: instrument.instrumentType,
          name: instrument.name,
          price: {
            currency: instrument.price ? instrument.price.currency : null,
          },
          purchasePrice: Number(purchasePrice.toFixed(defaultDecimalPlaces)),
          sedol: instrument.sedol,
        };

        // Set state and update total cost
        this.setState({
          item: item,
          ...handlePriceUpdateItemState({
            item: item,
            key: "perShareCost",
            perShareCostActive: true,
            purchaseCurrency: item.price.currency,
            totalCost: 0,
            totalCostActive: false,
            val: item.purchasePrice,
          }),
          instrumentProcessing: false,
        });
      })
      .catch((error: ApiError) => {
        console.error(error.data);
        this.setState({ error: error.data as ApiDataError });
      });
  }

  render() {
    const { buttonContainerClass, buttonType } = this.props;

    const {
      instrumentProcessing,
      portfoliosProcessing,
      portfolios,
      item,
      maxPortfolios,
    } = this.state;

    if (instrumentProcessing) {
      return <div>Please wait...</div>;
    }

    // Errors
    const formGeneralErrors =
      this.state.error &&
      this.state.error.errors.filter(
        (error: any) =>
          error.field === "" ||
          error.field === null ||
          typeof error.field === "undefined"
      );

    const portfolioIdError =
      this.state.error &&
      this.state.error.errors.find(
        (error: any) => error.field === "portfolioId"
      );

    const newPortfolioNameError =
      this.state.error &&
      this.state.error.errors.find(
        (error: any) => error.field === "newPortfolioName"
      );

    const purchasePriceError =
      this.state.error &&
      this.state.error.errors.find(
        (error: any) => error.field === "purchasePrice"
      );

    const unitsError =
      this.state.error &&
      this.state.error.errors.find((error: any) => error.field === "units");

    const purchaseDateError =
      this.state.error &&
      this.state.error.errors.find(
        (error: any) => error.field === "purchaseDate"
      );

    const notesError =
      this.state.error &&
      this.state.error.errors.find((error: any) => error.field === "notes");

    // Labels
    let unitsLabel = "Number of shares";
    let unitsStep = 1;
    let unitsDescription: string | undefined = "whole numbers only";
    let perShareCostDescription = "Per share cost";

    if (item.instrumentType === "FUND") {
      unitsLabel = "Number of units";
      unitsStep = 0.0001;
      unitsDescription = "Numbers with maximum four decimal places";
      perShareCostDescription = "per unit cost";
    }

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          this.onSubmit();
        }}
        css={styles.container}
      >
        {!portfoliosProcessing && (
          <Select
            label="Add to"
            size="medium"
            name="portfolioId"
            options={portfolios.map((p) => ({
              name: p.name,
              value: p.id,
            }))}
            selectedOptionValue={
              this.state.newPortfolio ? "" : this.state.portfolioId
            }
            onChange={(id: string) =>
              this.setState({
                newPortfolio: false,
                portfolioId: id,
              })
            }
            disabled={this.state.portfoliosProcessing}
            optional={this.state.newPortfolio}
            error={portfolioIdError ? portfolioIdError.message : undefined}
          />
        )}
        {portfolios.length < maxPortfolios && (
          <Checkbox
            name="newPortfolio"
            label="Or"
            checked={this.state.newPortfolio}
            onCheckboxChange={(isChecked: boolean) =>
              this.setState({
                newPortfolio: isChecked,
              })
            }
            size="small"
            readOnly={this.state.portfoliosProcessing}
            description="Create a new virtual portfolio"
          />
        )}

        {this.state.newPortfolio && (
          <Input
            name="newPortfolioName"
            type="text"
            size="medium"
            label="Name of new virtual portfolio"
            value={this.state.newPortfolioName || ""}
            onChange={(name: string) =>
              this.setState({
                newPortfolioName: name,
              })
            }
            autoComplete={false}
            readOnly={this.state.portfoliosProcessing}
            error={
              newPortfolioNameError ? newPortfolioNameError.message : undefined
            }
            selectOnFocus={true}
          />
        )}

        <Input
          name="units"
          type="number"
          size="small"
          label={unitsLabel}
          value={item.units}
          onChange={(units: number) => this.onItemPriceChange("units", units)}
          autoComplete={false}
          readOnly={this.state.portfoliosProcessing}
          error={unitsError ? unitsError.message : undefined}
          min={0}
          step={unitsStep}
          helper={unitsDescription}
          selectOnFocus={true}
        />

        <div css={styles.radio_container}>
          <RadioWithInput
            name="perShareCost"
            label={perShareCostDescription}
            description={perShareCostDescription}
            index={0}
            selected={this.state.perShareCostActive}
            onSelect={() => {
              this.setState({
                perShareCostActive: true,
                totalCostActive: false,
              });
            }}
            onChange={(value) => this.onItemPriceChange("perShareCost", value)}
            value={item.purchasePrice}
            readOnly={!this.state.perShareCostActive}
            error={purchasePriceError ? purchasePriceError.message : undefined}
            step={
              item.price.currency && item.price.currency.code === "GBX"
                ? 0.0001
                : 0.001
            }
            prefix={item.price.currency ? item.price.currency.prefix : ""}
            suffix={item.price.currency ? item.price.currency.suffix : ""}
          />

          <RadioWithInput
            name="totalCost"
            label="Total cost"
            description="Total cost"
            index={1}
            selected={this.state.totalCostActive}
            onSelect={() => {
              this.setState({
                perShareCostActive: false,
                totalCostActive: true,
              });
            }}
            onChange={(value) => this.onItemPriceChange("totalCost", value)}
            value={this.state.totalCost}
            readOnly={!this.state.totalCostActive}
            step={0.001}
            prefix={
              item.price.currency
                ? item.price.currency.code === "GBX"
                  ? "£"
                  : item.price.currency.prefix
                : ""
            }
            suffix={
              item.price.currency
                ? item.price.currency.code === "GBX"
                  ? ""
                  : item.price.currency.suffix
                : ""
            }
          />
        </div>

        <PurchaseDate
          containerClass={styles.date_container}
          inputsClass={styles.date_container_inputs}
          errorClass={styles.date_container_inputs_error}
          onItemDateChange={(key: string, value: number) =>
            this.onItemDateChange(key, value)
          }
          error={purchaseDateError}
          processing={portfoliosProcessing}
          purchaseDate={item.purchaseDate}
        />

        <Checkbox
          name="addNotes"
          checked={item.addNotes}
          onCheckboxChange={(isChecked: boolean) =>
            this.onItemChange("addNotes", isChecked)
          }
          size="small"
          readOnly={this.state.portfoliosProcessing}
          description="Add a note"
        />

        {item.addNotes && (
          <Notes
            containerClass={styles.notes}
            notes={item.notes}
            onItemChange={(notes: string) => this.onItemChange("notes", notes)}
            processing={portfoliosProcessing}
            error={notesError}
            notesRequired={true}
          />
        )}

        {((this.state.newPortfolioName && this.state.newPortfolioName !== "") ||
          this.state.portfolioId !== "") &&
          item.name && (
            <div css={buttonContainerClass}>
              <Button
                type={buttonType ? ButtonTypeEnum[buttonType] : "secondary"}
                size="medium"
                disabled={portfoliosProcessing}
              >
                add to virtual portfolio
              </Button>
            </div>
          )}

        {formGeneralErrors && (
          <div className="FormErrors__container">
            {formGeneralErrors.length === 0 && (
              <p>Please check the form for errors</p>
            )}
            {formGeneralErrors.length > 0 &&
              formGeneralErrors.map((error, i) => (
                <p key={i}>{error.message}</p>
              ))}
          </div>
        )}
      </form>
    );
  }
}

export default withAuth(VirtualPortfolioAddItemForm);
