import * as React from "react";

import VirtualPortfolioCreatePortfolioForm from "../../../components/VirtualPortfolio/components/CreatePortfolioForm";

import { VPortfolioViewNoPortfoliosProps } from "../interfaces/props";

const VPortfolioViewNoPortfolios: React.FC<VPortfolioViewNoPortfoliosProps> = ({
  containerClass,
}) => {
  return (
    <div className="GridRow" css={containerClass}>
      <div className="GridCell-xs-auto GridCell-lg-8 GridCell-offset-lg-2">
        <h1 className="text-lowercase">Virtual portfolio</h1>

        <p>
          Welcome to the ii virtual portfolio, to get started choose a name to
          call your first virtual portfolio. You can then use{" "}
          <strong>search &amp; add</strong> or visit an investment page and use
          add to virtual portfolio.
        </p>

        <VirtualPortfolioCreatePortfolioForm
          portfolioNameLabel="Name of your first virtual portfolio"
          portfolioNamePlaceholder="enter name..."
        />
      </div>
    </div>
  );
};

export default VPortfolioViewNoPortfolios;
