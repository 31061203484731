import * as React from "react";

import { Button } from "@interactive-investor/onestack-web-shared";

import { VPortfolioViewNoPortfolioItemsProps } from "../interfaces/props";

const VPortfolioViewNoPortfolioItems = ({
  containerClass,
  buttonContainerClass,
  onSearchAndAddButtonClick,
}: VPortfolioViewNoPortfolioItemsProps) => (
  <div className="GridRow" css={containerClass}>
    <div className="GridCell-xs-auto GridCell-lg-8 GridCell-offset-lg-2">
      <p>
        You do not hold any investments in this virtual portfolio yet. Use{" "}
        <strong>search &amp; add</strong> or visit an investment page and use{" "}
        <i>add to virtual portfolio</i>.
      </p>

      <div css={buttonContainerClass}>
        <Button
          type="secondary"
          size="medium"
          leftIcon="search"
          leftIconSet="fal"
          onClick={() => onSearchAndAddButtonClick()}
        >
          Search &amp; Add
        </Button>
      </div>
    </div>
  </div>
);

export default VPortfolioViewNoPortfolioItems;
