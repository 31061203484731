import { CSSObject } from "@emotion/css";
import * as React from "react";

import { Button, Input } from "@interactive-investor/onestack-web-shared";

export interface PortfolioNameEditProps {
  name: string;
  containerClass?: string;
  cssStyle?: CSSObject;
  onSave: (value: string) => void;
  saveError?: string;
  saveSuccess: boolean;
  processing: boolean;
}

export interface PortfolioNameEditState {
  name: string;
  needsSave: boolean;
  saved: boolean;
}

const PortfolioNameEdit: React.FC<PortfolioNameEditProps> = ({
  containerClass,
  cssStyle,
  processing,
  saveError,
  saveSuccess,
  name,
  onSave,
}) => {
  const [portfolioName, setPortfolioName] = React.useState(name);
  const [needsSave, setNeedsSave] = React.useState(false);
  const [saved, setSaved] = React.useState(false);

  const onNameChange = (nameText: string) => {
    setPortfolioName(nameText);
    setNeedsSave(true);
    setSaved(false);
  };

  const onSaveFunc = () => {
    if (!processing) {
      onSave(portfolioName);
    }
  };

  React.useEffect(() => {
    if (saveSuccess && needsSave) {
      setNeedsSave(false);
      setSaved(true);
    }
  }, [saveSuccess]);

  return (
    <form
      className={containerClass || ""}
      css={cssStyle}
      onSubmit={(e) => {
        e.preventDefault();
        onSaveFunc();
      }}
    >
      <Input
        type="text"
        size="small"
        value={portfolioName}
        name="portfolioName"
        onChange={(inputValue: string) => onNameChange(inputValue)}
        error={saveError}
      />

      {(needsSave || saved) && (
        <div>
          <Button
            type={saved ? "success" : "secondary"}
            size="medium"
            disabled={processing || saved}
          >
            {processing
              ? "Saving"
              : saved && !needsSave
              ? "Saved"
              : "Save Name"}
          </Button>
        </div>
      )}
    </form>
  );
};

export default PortfolioNameEdit;
