import * as React from "react";

import { Icon } from "@interactive-investor/onestack-web-shared";

import { VPortfolioActionsHelpModalProps } from "./interfaces/props";

import { styles } from "./styles/actionsHelp";

const ActionsHelpModal: React.FC<VPortfolioActionsHelpModalProps> = () => {
  return (
    <div css={styles.container}>
      <div>
        <div css={styles.actionItem}>
          <div css={styles.iconContainer}>
            <Icon identifier="newspaper" set="fal" />
          </div>
          <div css={styles.actionCopy}>
            <h3>Regulatory News (UK companies)</h3>
            <p>
              Clicking the news icon (
              {<Icon identifier="newspaper" set="fal" />}) will take you to the
              company’s regulatory news page. A blue counter will show the
              number of articles relating to the company, if applicable. The
              counter will be updated shortly before UK markets open, and be
              reset at the end of each day.
            </p>
          </div>
        </div>

        <div css={styles.actionItem}>
          <div css={styles.iconContainer}>
            <Icon identifier="pen" set="fal" />
          </div>
          <div css={styles.actionCopy}>
            <h3>Edit</h3>
            <p>
              Clicking the edit icon ({<Icon identifier="pen" set="fal" />})
              will lead you will lead you to the edit view for a holding.
            </p>
          </div>
        </div>

        <div css={styles.actionItem}>
          {" "}
          <div css={styles.iconContainer}>
            <Icon identifier="ellipsis-h" set="far" />
          </div>
          <div css={styles.actionCopy}>
            <h3>All actions</h3>
            <p>
              Clicking the all actions icon (
              {<Icon identifier="ellipsis-h" set="far" />}) will open up a menu
              showing you all possible actions for a holding.
            </p>
          </div>
        </div>

        <div css={styles.actionItem}>
          <div css={styles.iconContainer}>
            <Icon identifier="sticky-note" set="fal" />
          </div>
          <div css={styles.actionCopy}>
            <h3>Notes</h3>
            <p>
              Choosing the notes option (
              {<Icon identifier="sticky-note" set="fal" />} notes) in the all
              actions menu ({<Icon identifier="ellipsis-h" set="far" />}) will
              lead you to the edit view where you can changes or add notes for a
              holding.
            </p>
          </div>
        </div>

        <div css={styles.actionItem}>
          <div css={styles.iconContainer}>
            <Icon identifier="trash" set="fal" />
          </div>
          <div css={styles.actionCopy}>
            <h3>Delete</h3>
            <p>
              Choosing the delete option (
              {<Icon identifier="trash" set="fal" />} delete) in the all actions
              menu ({<Icon identifier="ellipsis-h" set="far" />}) will open up a
              dialogue window asking you to confirm whether you want to
              permanently delete an asset.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionsHelpModal;
