import { CSSObject } from "@emotion/css";

import {
  colors,
  grid,
  type,
  variables,
} from "@interactive-investor/onestack-web-shared";

export const itemsListWithDetailStyles = {
  container: {
    borderTop: `1px solid ${colors.black.uiLight}`,

    [grid.breakpointMixins.md]: {
      display: "flex",
    },
  } as CSSObject,

  list_container: {
    width: "100%",

    [grid.breakpointMixins.md]: {
      width: "250px",
      flexBasis: "250px",
    },
  } as CSSObject,

  list_item: {
    borderLeft: `4px solid ${colors.black.uiLight}`,
    borderRight: `1px solid ${colors.black.uiLight}`,
    borderBottom: `1px solid ${colors.black.uiLight}`,
    padding: `${variables.paddingXs} ${variables.paddingSm}`,
    cursor: "pointer",

    minHeight: "115px",

    "& h3": {
      ...type.textParagraph,
      textTransform: "none",
      marginBottom: variables.marginSm,
    },

    "& p": {
      ...type.textSmall,
      marginBottom: variables.marginSm,
    },

    "& p:last-of-type": {
      marginBottom: 0,
    },
  } as CSSObject,

  list_item_active: {
    borderLeftColor: colors.secondaryAction,
  } as CSSObject,

  item_container: {
    padding: `${variables.paddingMd} 0`,

    [grid.breakpointMixins.md]: {
      flex: 1,
      padding: `${variables.paddingMd} 0 ${variables.paddingLg} ${variables.paddingLg}`,
    },

    "& h2": {
      ...type.textParagraph,
      textTransform: "none",
      marginBottom: variables.marginSm,
    },
  } as CSSObject,

  item_header: {
    marginBottom: variables.marginLg,
  } as CSSObject,

  edit_form: {} as CSSObject,

  button_container: {
    display: "flex",
  } as CSSObject,
};
