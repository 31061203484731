import * as React from "react";

import { Input, Radio } from "@interactive-investor/onestack-web-shared";

export interface RadioWithInputProps {
  name: string;
  description?: string;
  label: string;
  selected: boolean;
  onSelect: (selected: boolean) => void;
  onChange: (value: number) => void;
  value: number;
  readOnly: boolean;
  error?: string;
  step: number;
  prefix?: string | null;
  suffix?: string | null;
  index?: number;
}

const RadioWithInput = ({
  name,
  label,
  selected,
  onSelect,
  onChange,
  value,
  readOnly,
  error,
  step,
  prefix,
  suffix,
  index,
}: RadioWithInputProps) => (
  <div>
    <Radio
      groupName={name}
      id={`${name}_${0}`}
      label={label}
      isDisabled={false}
      radioValue={selected}
      selected={selected}
      index={index}
      changeWithClick={(radioSelected: boolean) => onSelect(radioSelected)}
      name={`radio_${name}`}
    />
    <Input
      type="number"
      size="small"
      value={value}
      name={name}
      readOnly={readOnly}
      onChange={(inputValue: number) => onChange(inputValue)}
      error={error}
      min={0}
      step={step}
      prefix={prefix}
      suffix={suffix}
      selectOnFocus={true}
    />
  </div>
);

export default RadioWithInput;
