import moment from "moment";
import * as React from "react";

import {
  Button,
  ButtonTypeEnum,
  Input,
} from "@interactive-investor/onestack-web-shared";

import { withAuth } from "../../../contexts/Auth";
import { AuthContextInjectedProps } from "../../../contexts/Auth/interfaces/context";
import {
  ApiDataError,
  ApiError,
} from "../../../services/api/interfaces/apiError";
import VirtualPortfolioService from "../../../services/virtual-portfolio";

import Notes from "./formElements/Notes";
import PurchaseDate from "./formElements/PurchaseDate";
import { handlePriceUpdateItemState } from "./functions/itemPrice";
import RadioWithInput from "./RadioWithInput";

import { VirtualPortfolioEditItemFormProps } from "../interfaces/props";
import { VirtualPortfolioEditItemFormState } from "../interfaces/state";

import { itemFormStyles as styles } from "../styles/itemForm";

export type WithAuthProps = VirtualPortfolioEditItemFormProps &
  AuthContextInjectedProps;

class VirtualPortfolioEditItemForm extends React.Component<
  WithAuthProps,
  VirtualPortfolioEditItemFormState
> {
  static getDerivedStateFromProps(
    props: WithAuthProps,
    state: VirtualPortfolioEditItemFormState
  ) {
    if (props.portfolioItem.id !== state.item.id) {
      return {
        newItemIncoming: true,
      };
    }
    return null;
  }

  constructor(props: WithAuthProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState = () => ({
    error: undefined,
    newItemIncoming: false,
    portfolioItemProcessing: false,
    saveNeeded: false,
    ...this.getItemAndTotalsFromProps(),
  });

  getItemAndTotalsFromProps = () => {
    const { portfolioItem } = this.props;
    const purchaseDate = moment(portfolioItem.purchaseDate, "YYYY-MM-DD");

    const itemState = {
      addNotes: true,
      id: portfolioItem.id,
      instrumentType: portfolioItem.type,
      name: portfolioItem.name,
      notes: portfolioItem.notes,
      price: {
        currency: portfolioItem.price
          ? portfolioItem.price.currency
          : undefined,
      },
      purchaseDate: {
        day: purchaseDate.date(),
        month: purchaseDate.month() + 1,
        year: purchaseDate.year(),
      },
      purchasePrice: portfolioItem.purchasePrice,
      sedol: portfolioItem.sedol,
      units: portfolioItem.units,
    };

    const state = {
      item: itemState,
      perShareCostActive: true, // for value, item.purchasePrice is perShareCost from the UI
      totalCostActive: false,
      ...handlePriceUpdateItemState({
        item: itemState,
        key: "units",
        perShareCostActive: true,
        purchaseCurrency: itemState.price.currency,
        totalCost: 0,
        totalCostActive: false,
        val: itemState.units,
      }),
    };
    return state;
  };

  onItemChange = (key: string, value: string | number | boolean) => {
    this.setState({
      item: {
        ...this.state.item,
        [key]: value,
      },
      saveNeeded: true,
    });
  };

  onItemDateChange = (key: string, value: number) => {
    this.setState({
      item: {
        ...this.state.item,
        purchaseDate: {
          ...this.state.item.purchaseDate,
          [key]: value,
        },
      },
      saveNeeded: true,
    });
  };

  onItemPriceChange = (key: string, value: number) => {
    this.setState({
      saveNeeded: true,
      ...handlePriceUpdateItemState({
        item: this.state.item,
        key,
        perShareCostActive: this.state.perShareCostActive,
        purchaseCurrency: this.state.item.price.currency,
        totalCost: this.state.totalCost,
        totalCostActive: this.state.totalCostActive,
        val: value,
      }),
    });
  };

  onSubmit = () => {
    const { item } = this.state;
    const { portfolioItem } = this.props;

    if (this.state.portfolioItemProcessing !== true) {
      this.setState({
        portfolioItemProcessing: true,
      });

      const paddedMonth = String(item.purchaseDate.month).padStart(2, "0");
      const paddedDay = String(item.purchaseDate.day).padStart(2, "0");

      const purchasedDate = moment(
        `${item.purchaseDate.year}-${paddedMonth}-${paddedDay}`,
        "YYYY-MM-DD",
        true
      );

      if (!purchasedDate.isValid()) {
        this.setState({
          error: {
            errors: [
              {
                code: "INVALID",
                field: "purchaseDate",
                message: "Please enter a valid date",
              },
            ],
            status: 400,
          },
          portfolioItemProcessing: false,
        });
        return;
      }

      VirtualPortfolioService.updatePortfolioItem(
        portfolioItem.virtualPortfolioId,
        portfolioItem.id,
        {
          instrumentType: portfolioItem.type,
          name: item.name,
          notes: item.notes,
          purchaseDate: purchasedDate.format("YYYY-MM-DD"),
          purchasePrice: item.purchasePrice,
          sedol: portfolioItem.sedol,
          units: item.units,
        },
        this.props.auth.getAccessTokenFree() as string
      )
        .then((response: any) => {
          this.setState({
            portfolioItemProcessing: false,
            saveNeeded: false,
          });
          this.props.onUpdatePortfolioItemSuccess();
        })
        .catch((error: ApiError) => {
          console.error(error, error.data);
          this.setState({ error: error.data as ApiDataError });
        });
    }
  };

  onReset = () => {
    this.setState({
      ...this.getItemAndTotalsFromProps(),
      error: undefined,
      portfolioItemProcessing: false,
      saveNeeded: false,
    });
  };

  componentDidUpdate(
    prevProps: WithAuthProps,
    prevState: VirtualPortfolioEditItemFormState
  ) {
    if (this.state.newItemIncoming) {
      this.setState({
        ...this.getItemAndTotalsFromProps(),
        error: undefined,
        instrumentProcessing: true,
        newItemIncoming: false,
        portfolioItemProcessing: false,
        saveNeeded: false,
      });
    }
  }

  render() {
    const { buttonContainerClass, buttonType } = this.props;

    const { newItemIncoming, portfolioItemProcessing, item } = this.state;

    if (newItemIncoming) {
      return <div>Please wait...</div>;
    }

    // Errors
    const formGeneralErrors =
      this.state.error &&
      this.state.error.errors.filter(
        (error: any) =>
          error.field === "" ||
          error.field === null ||
          typeof error.field === "undefined"
      );

    const purchasePriceError =
      this.state.error &&
      this.state.error.errors.find(
        (error: any) => error.field === "purchasePrice"
      );

    const unitsError =
      this.state.error &&
      this.state.error.errors.find((error: any) => error.field === "units");

    const purchaseDateError =
      this.state.error &&
      this.state.error.errors.find(
        (error: any) => error.field === "purchaseDate"
      );

    const notesError =
      this.state.error &&
      this.state.error.errors.find((error: any) => error.field === "notes");

    // Labels
    let unitsLabel = "Number of shares";
    let unitsStep = 1;
    let unitsDescription: string | undefined = "Whole numbers only";
    let perShareCostDescription = "Per share cost";

    if (item.instrumentType === "FUND") {
      unitsLabel = "Number of units";
      unitsStep = 0.0001;
      unitsDescription = "Numbers with maximum four decimal places";

      perShareCostDescription = "per unit cost";
    }

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          this.onSubmit();
        }}
        css={styles.container}
      >
        <div css={styles.edit_form_row}>
          <Input
            name="units"
            type="number"
            size="small"
            label={unitsLabel}
            value={item.units}
            onChange={(units: number) => this.onItemPriceChange("units", units)}
            autoComplete={false}
            readOnly={portfolioItemProcessing}
            error={unitsError ? unitsError.message : undefined}
            min={0}
            step={unitsStep}
            helper={unitsDescription}
            cssStyles={[styles.edit_form_units]}
            selectOnFocus={true}
          />

          <div css={[styles.radio_container, styles.edit_radio_container]}>
            <RadioWithInput
              name="perShareCost"
              label="Per Share Cost"
              index={0}
              description={perShareCostDescription}
              selected={this.state.perShareCostActive}
              onSelect={(selected) =>
                this.setState({
                  perShareCostActive: true,
                  totalCostActive: false,
                })
              }
              onChange={(value) =>
                this.onItemPriceChange("perShareCost", value)
              }
              value={item.purchasePrice}
              readOnly={!this.state.perShareCostActive}
              error={
                purchasePriceError ? purchasePriceError.message : undefined
              }
              step={
                item.price.currency && item.price.currency.code === "GBX"
                  ? 0.0001
                  : 0.001
              }
              prefix={item.price.currency ? item.price.currency.prefix : ""}
              suffix={item.price.currency ? item.price.currency.suffix : ""}
            />

            <RadioWithInput
              name="totalCost"
              label={"Total Cost"}
              index={1}
              description="Total cost"
              selected={this.state.totalCostActive}
              onSelect={(selected) =>
                this.setState({
                  perShareCostActive: false,
                  totalCostActive: true,
                })
              }
              onChange={(value) => this.onItemPriceChange("totalCost", value)}
              value={this.state.totalCost}
              readOnly={!this.state.totalCostActive}
              step={0.01}
              prefix={
                item.price.currency
                  ? item.price.currency.code === "GBX"
                    ? "£"
                    : item.price.currency.prefix
                  : ""
              }
              suffix={
                item.price.currency
                  ? item.price.currency.code === "GBX"
                    ? ""
                    : item.price.currency.suffix
                  : ""
              }
            />
          </div>
          <div css={styles.edit_purchase_dates}>
            <PurchaseDate
              containerClass={styles.edit_form_date_container}
              inputsClass={styles.date_container_inputs}
              formClass={styles.dateInput}
              errorClass={styles.date_container_inputs_error}
              onItemDateChange={(key: string, value: number) =>
                this.onItemDateChange(key, value)
              }
              error={purchaseDateError}
              processing={portfolioItemProcessing}
              purchaseDate={item.purchaseDate}
            />
          </div>
        </div>

        <Notes
          containerClass={styles.notes}
          notes={item.notes}
          onItemChange={(notes: string) => this.onItemChange("notes", notes)}
          processing={portfolioItemProcessing}
          error={notesError}
          notesRequired={false}
        />

        {this.state.saveNeeded && (
          <div css={buttonContainerClass}>
            <Button
              type={
                this.state.portfolioItemProcessing
                  ? "disabled"
                  : buttonType
                  ? ButtonTypeEnum[buttonType]
                  : "secondary"
              }
              size="medium"
              leftIcon="save"
              leftIconSet="fal"
            >
              {this.state.portfolioItemProcessing ? "Saving" : "Save Changes"}
            </Button>

            <Button type="link" size="medium" onClick={() => this.onReset()}>
              Cancel
            </Button>
          </div>
        )}

        {formGeneralErrors && (
          <div className="FormErrors__container">
            {formGeneralErrors.length === 0 && (
              <p>Please check the form for errors</p>
            )}
            {formGeneralErrors.length > 0 &&
              formGeneralErrors.map((error, i) => (
                <p key={i}>{error.message}</p>
              ))}
          </div>
        )}
      </form>
    );
  }
}

export default withAuth(VirtualPortfolioEditItemForm);
