import * as React from "react";

import { Button } from "@interactive-investor/onestack-web-shared";

import VirtualPortfolioCreatePortfolioForm from "./components/CreatePortfolioForm";

import { VPortfolioCreatePortfolioModalProps } from "./interfaces/props";

import { createPortfolioModalStyles as modalStyles } from "./styles/createPortfolio";
import { createPortfolioFormStyles as formStyles } from "./styles/createPortfolio";

const VPortfolioCreatePortfolioModal: React.FC<VPortfolioCreatePortfolioModalProps> = ({
  onCloseButtonClick,
  countPortfolios,
  maxPortfolios,
}) => {
  return (
    <div css={formStyles.container}>
      {countPortfolios < maxPortfolios && (
        <VirtualPortfolioCreatePortfolioForm
          buttonContainerClass={modalStyles.button_container}
        />
      )}

      {countPortfolios >= maxPortfolios && (
        <p>
          You have reached the maximum number of portfolios ({maxPortfolios}),
          please edit or delete an existing portfolio
        </p>
      )}

      <div css={modalStyles.button_container}>
        <Button type="link" size="medium" onClick={() => onCloseButtonClick()}>
          cancel
        </Button>
      </div>
    </div>
  );
};

export default VPortfolioCreatePortfolioModal;
