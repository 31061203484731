import { unescape } from "lodash";
import React from "react";

import {
  Button,
  navigateTo,
  SelectDropdown,
} from "@interactive-investor/onestack-web-shared";

import { VirtualPortfolioInterface } from "../../services/virtual-portfolio/interfaces/virtualPortfolio";

import PortfolioNameEdit from "./components/PortfolioNameEdit";
import { VPortfolioHeaderProps } from "./interfaces/props";
import { headerStyles as styles } from "./styles/header";

class VPortfolioHeader extends React.Component<
  VPortfolioHeaderProps
> {
  focusButtonCreateVP: React.RefObject<HTMLDivElement>;
  focusButtonSearchVP: React.RefObject<HTMLDivElement>;
  focusButtonDeleteVP: React.RefObject<HTMLDivElement>;

  constructor(props: VPortfolioHeaderProps) {
    super(props);
    this.focusButtonCreateVP = React.createRef();
    this.focusButtonSearchVP = React.createRef();
    this.focusButtonDeleteVP = React.createRef();
  }

  returnRefCreateFocus() {
    if (this.focusButtonCreateVP && this.focusButtonCreateVP.current) {
      this.focusButtonCreateVP.current.focus();
    }
  }

  returnRefDeleteFocus() {
    if (this.focusButtonSearchVP && this.focusButtonSearchVP.current) {
      this.focusButtonSearchVP.current.focus();
    }
  }
  returnRefSearchFocus() {
    if (this.focusButtonDeleteVP && this.focusButtonDeleteVP.current) {
      this.focusButtonDeleteVP.current.focus();
    }
  }

  componentDidUpdate(prevProps: VPortfolioHeaderProps) {
    if (prevProps.isCreateVPModalOpen !== this.props.isCreateVPModalOpen) {
      this.returnRefCreateFocus();
    }
    if (prevProps.isSearchVPModalOpen !== this.props.isSearchVPModalOpen) {
      this.returnRefDeleteFocus();
    }
    if (prevProps.isDeleteVPModalOpen !== this.props.isDeleteVPModalOpen) {
      this.returnRefSearchFocus();
    }
  }

  render() {
    const {
      portfolios,
      countPortfolios,
      maxPortfolios,
      portfolio,
      portfolioItemsProcessing,
      portfolioItemTypeFilter,
      onCreatePortfolioButtonClick,
      onSearchAndAddButtonClick,
      onDeletePortfolioButtonClick,
      onEditViewButtonClick,
      editView,
      editPortfolioNameProcessing,
      editPortfolioNameSuccess,
      editPortfolioNameError,
      onSavePortfolioNameButtonClick,
      isCreateVPModalOpen,
      isDeleteVPModalOpen,
      isSearchVPModalOpen,
    } = this.props;

    let headerDropdownActiveItemIndex = 0;
    if (countPortfolios > 0 && portfolio) {
      headerDropdownActiveItemIndex = portfolios.findIndex(
        (p: VirtualPortfolioInterface) => p.id === portfolio.id
      );
    }

    const editClass = editView ? styles.editNavButton : {};

    return (
      <div css={styles.container}>
        <div className="GridRow">
          <div className="GridCell-xs-grow">
            <h1>Virtual portfolio</h1>
          </div>
          <div className="GridCell-xs-shrink">
            {countPortfolios > 0 &&
              countPortfolios < maxPortfolios &&
              !editView && (
                <div
                  ref={this.focusButtonCreateVP}
                  tabIndex={isCreateVPModalOpen ? 0 : -1}
                >
                  <Button
                    type="primary"
                    size="medium"
                    leftIcon="plus"
                    leftIconSet="fal"
                    cssStyles={[styles.header_button]}
                    onClick={() => {
                      onCreatePortfolioButtonClick();
                    }}
                  >
                    add portfolio
                  </Button>
                </div>
              )}
          </div>
        </div>

        <div css={styles.nav} className="GridRow">
          <div className="GridRow-xs-12">
            {countPortfolios > 0 && !editView && (
              <div className="GridCell-xs-auto GridCell-md-shrink">
                <span css={styles.label}>Portfolio:</span>
                <SelectDropdown
                  type="floating"
                  label="Portfolios"
                  size="medium"
                  items={portfolios.map((p: VirtualPortfolioInterface) => ({
                    label: unescape(p.name),
                    onClick: () => navigateTo(`/virtual-portfolio/${p.id}`),
                  }))}
                  activeItemIndex={headerDropdownActiveItemIndex}
                  showArrow={true}
                />
              </div>
            )}
          </div>

          {!portfolioItemsProcessing && portfolio && editView && (
            <div
              css={styles.nameEditContainer}
              className="GridCell-xs-auto GridCell-md-shrink"
            >
              <PortfolioNameEdit
                name={portfolio.name}
                cssStyle={styles.header_name_edit}
                onSave={(newName: string) =>
                  onSavePortfolioNameButtonClick(newName)
                }
                processing={editPortfolioNameProcessing}
                saveSuccess={editPortfolioNameSuccess}
                saveError={editPortfolioNameError}
              />
            </div>
          )}

          {!portfolioItemsProcessing && portfolio && (
            <div
              css={[styles.buttonContainer, editClass]}
              className={`${
                editView ? "GridCell-xs-6" : "GridCell-xs-4"
              } GridCell-md-shrink`}
            >
              <Button
                type="link"
                size="medium"
                leftIcon={editView ? "long-arrow-alt-left" : "pencil-alt"}
                onClick={() => onEditViewButtonClick()}
                leftIconSet={editView ? "far" : "fal"}
              >
                {!editView ? `edit view` : `back to table view`}
              </Button>
            </div>
          )}

          {!portfolioItemsProcessing &&
            portfolio &&
            ((portfolio.items.length > 0 &&
              portfolioItemTypeFilter === "ALL") ||
              portfolioItemTypeFilter !== "ALL") &&
            !editView &&
            portfolio.countPortfolioItems < portfolio.maxPortfolioItems && (
              <div
                css={styles.buttonContainer}
                className={`${
                  editView ? "GridCell-xs-6" : "GridCell-xs-4"
                } GridCell-md-shrink`}
                ref={this.focusButtonSearchVP}
                tabIndex={isSearchVPModalOpen ? 0 : -1}
              >
                <Button
                  type="link"
                  size="medium"
                  leftIcon="search"
                  onClick={() => onSearchAndAddButtonClick()}
                >
                  search &amp; add
                </Button>
              </div>
            )}

          {!portfolioItemsProcessing && portfolio && (
            <div
              css={[styles.buttonContainer, editClass]}
              className={`${
                editView ? "GridCell-xs-6" : "GridCell-xs-4"
              } GridCell-md-shrink`}
              ref={this.focusButtonDeleteVP}
              tabIndex={isDeleteVPModalOpen ? 0 : -1}
            >
              <Button
                type="link"
                size="medium"
                leftIcon="trash-alt"
                leftIconSet="fal"
                onClick={() => onDeletePortfolioButtonClick()}
              >
                delete portfolio
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default VPortfolioHeader;
