import * as React from "react";

import { Button } from "@interactive-investor/onestack-web-shared";

import { VPortfolioDeletePortfolioModalProps } from "./interfaces/props";

import { deletePortfolioStyles as styles } from "./styles/delete";

class VPortfolioDeletePortfolioModal extends React.Component<
  VPortfolioDeletePortfolioModalProps
> {
  render() {
    const { onDeletePortfolio, onCloseButtonClick } = this.props;

    return (
      <div css={styles.container}>
        <p css={styles.notice}>
          Please confirm that you want to permanently delete this virtual
          portfolio. Please keep in mind that this action can not be reversed.
        </p>

        <div css={styles.button_container}>
          <Button
            type="secondary"
            size="medium"
            onClick={() => onDeletePortfolio()}
          >
            delete virtual portfolio
          </Button>
        </div>

        <div css={styles.button_container}>
          <Button
            type="link"
            size="medium"
            onClick={() => onCloseButtonClick()}
          >
            cancel
          </Button>
        </div>
      </div>
    );
  }
}

export default VPortfolioDeletePortfolioModal;
