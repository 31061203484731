import {
  EmotionStyles,
  variables,
} from "@interactive-investor/onestack-web-shared";

export const createPortfolioModalStyles: EmotionStyles = {
  button_container: {
    textAlign: "center",
  },
};

export const createPortfolioFormStyles: EmotionStyles = {
  container: {
    "& input": {
      width: "100%",
    },
  },

  button: {
    marginTop: variables.marginLg,

    "& span": {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
};
