import { truncate } from "lodash";
import * as React from "react";

import { EmotionStyles } from "@interactive-investor/onestack-web-shared";

import {
  VirtualPortfolioItemInterface,
  VirtualPortfolioWithItemsInterface,
} from "../../../services/virtual-portfolio/interfaces/virtualPortfolio";

export interface ItemsListCardsProps {
  activeItemId?: string;
  styles: EmotionStyles;
  portfolio: VirtualPortfolioWithItemsInterface;
  onItemClick: (portfolioItemId: string) => void;
}

const ItemsListCards: React.FC<ItemsListCardsProps> = ({
  styles,
  portfolio,
  activeItemId,
  onItemClick,
}) => (
  <div css={styles.list_container}>
    {portfolio.items.map((item: VirtualPortfolioItemInterface, i) => (
      <div
        css={[
          styles.list_item,
          item.id === activeItemId ? styles.list_item_active : undefined,
        ]}
        key={i}
        onClick={() => onItemClick(item.id)}
      >
        <h3>{item.name}</h3>
        <p>Added on {item.purchaseDate}</p>
        <p>
          {item.notes && item.notes !== ""
            ? truncate(item.notes, {
                length: 50,
                separator: /.? +/,
              }) + "..."
            : "no notes"}
        </p>
      </div>
    ))}
  </div>
);

export default ItemsListCards;
