import { CSSObject } from "@emotion/core";
import * as React from "react";
import { ApiDataErrorItem } from "../../../../services/api/interfaces/apiError";

import { Fieldset, Input } from "@interactive-investor/onestack-web-shared";

interface PurchaseDateProps {
  containerClass: CSSObject;
  inputsClass: CSSObject;
  formClass?: CSSObject;
  errorClass: CSSObject;
  onItemDateChange: (key: string, value: number) => void;
  error?: ApiDataErrorItem;
  processing: boolean;
  purchaseDate: {
    day: number;
    month: number;
    year: number;
  };
}

export const PurchaseDate: React.FC<PurchaseDateProps> = ({
  containerClass,
  inputsClass,
  formClass,
  errorClass,
  onItemDateChange,
  error,
  processing,
  purchaseDate,
}) => {
  return (
    <Fieldset cssStyles={[containerClass]} legend="Transaction Date">
      <div css={inputsClass}>
        <Input
          name="purchaseDateDay"
          type="number"
          size="small"
          label="Day"
          value={purchaseDate.day}
          onChange={(day: number) => onItemDateChange("day", day)}
          autoComplete={false}
          readOnly={processing}
          min={1}
          max={31}
          step={1}
          selectOnFocus={true}
          cssStyles={formClass ? [formClass] : [{}]}
        />
        <Input
          name="purchaseDateMonth"
          type="number"
          size="small"
          label="Month"
          value={purchaseDate.month}
          onChange={(month: number) => onItemDateChange("month", month)}
          autoComplete={false}
          readOnly={processing}
          min={1}
          max={12}
          step={1}
          selectOnFocus={true}
          cssStyles={formClass ? [formClass] : [{}]}
        />
        <Input
          name="purchaseDateYear"
          type="number"
          size="small"
          label="Year"
          value={purchaseDate.year}
          onChange={(year: number) => onItemDateChange("year", year)}
          autoComplete={false}
          readOnly={processing}
          min={1900}
          max={2100}
          step={1}
          selectOnFocus={true}
          cssStyles={formClass ? [formClass] : [{}]}
        />
      </div>

      {error && <div css={errorClass}>{error.message}</div>}
    </Fieldset>
  );
};

export default PurchaseDate;
