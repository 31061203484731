import { CSSObject } from "@emotion/css";

import {
  grid,
  type,
  variables,
} from "@interactive-investor/onestack-web-shared";

export const headerStyles = {
  container: {},
  header_button: {
    padding: "3px",
    justifyContent: "center",
    "& span": {
      fontSize: type.textSmall,
      "& svg": {
        paddingRight: 0,
        [grid.breakpointMixins.md]: {
          paddingRight: "8px",
          width: "22px !important",
        },
      },
    },
    "& i": {
      paddingRight: 0,
    },

    [grid.breakpointMixins.md]: {
      padding: `0 ${variables.paddingMd}`,
      width: "auto",
      "& span": {
        visibility: "visible",
        width: "auto",
        fontSize: type.baseUnitMdInt,
      },
      "& i": {
        paddingRight: variables.paddingSm,
      },
    },
  } as CSSObject,

  nav: {
    alignItems: "center",
    marginBottom: variables.marginMd,
  } as CSSObject,

  buttonContainer: {
    padding: `${variables.paddingMd} 0 0`,

    [grid.breakpointMixins.md]: {
      padding: 0,
    },

    "& span": {
      fontSize: type.textSmall.fontSize,

      [grid.breakpointMixins.md]: {
        fontSize: "inherit",
      },
    },

    "& button": {
      paddingLeft: 0,

      [grid.breakpointMixins.md]: {
        paddingLeft: variables.paddingMd,
      },
    },
  } as CSSObject,

  label: {
    marginRight: variables.marginSm,
  },

  editNavButton: {
    [grid.breakpointMixins.md]: {
      order: 2,
    },
  },

  nameEditContainer: {
    order: 3,

    [grid.breakpointMixins.md]: {
      order: 1,
    },
  } as CSSObject,

  header_name_edit: {
    display: "block",

    [grid.breakpointMixins.md]: {
      display: "flex",
      alignItems: "flex-end",
    },

    "& div, & .FormInput__input_container, & button": {
      margin: 0,
    },

    "& input[type=text]": {
      ...type.textParagraph,
      width: "100%",

      [grid.breakpointMixins.md]: {
        width: "250px",
        marginRight: variables.marginSm,
      },
    },
  } as CSSObject,
};
