import { Location, Router } from "@reach/router";
import React from "react";

import VirtualPortfolioContainer from "../../containers/VirtualPortfolio";
import VirtualPortfolioAddInstrumentContainer from "../../containers/VirtualPortfolio/addInstrument";
import VirtualPortfolioPageContainer from "../../containers/VirtualPortfolio/portfolioPage";
import RequireAuthFreeWrapper, {
  RequireAuthFreeWrapperProps,
} from "../../contexts/Auth/RequireAuthFreeWrapper";

import Layout from "../../components/layout";
import Redirect from "../../components/redirect";
import { Route } from "../../helpers/routing";

export interface VirtualPortfolioPageProps {
  data: any;
  location: any;
}

export interface VirtualPortfolioPageState {
  mounted: boolean;
}

const Portfolio = (props: any) => {
  return (
    <Location>
      {({ location }) => {
        const authWrapperProps = {
          location,
          name: "Virtual Portfolio",
        } as RequireAuthFreeWrapperProps;

        return (
          <RequireAuthFreeWrapper {...authWrapperProps}>
            <VirtualPortfolioContainer {...props} />
          </RequireAuthFreeWrapper>
        );
      }}
    </Location>
  );
};

const PortfolioAddInstrument = (props: any) => {
  return (
    <Location>
      {({ location }) => {
        const authWrapperProps = {
          location,
          name: "Virtual Portfolio - Add Instrument",
        } as RequireAuthFreeWrapperProps;

        return (
          <RequireAuthFreeWrapper {...authWrapperProps}>
            <VirtualPortfolioAddInstrumentContainer {...props} />
          </RequireAuthFreeWrapper>
        );
      }}
    </Location>
  );
};

const PortfolioPage = (props: any) => {
  return (
    <Location>
      {({ location }) => {
        const authWrapperProps = {
          location,
          name: "Virtual Portfolio",
        } as RequireAuthFreeWrapperProps;

        return (
          <RequireAuthFreeWrapper {...authWrapperProps}>
            <VirtualPortfolioPageContainer {...props} />
          </RequireAuthFreeWrapper>
        );
      }}
    </Location>
  );
};

class VirtualPortfolioPage extends React.Component<
  VirtualPortfolioPageProps,
  VirtualPortfolioPageState
> {
  constructor(props: VirtualPortfolioPageProps) {
    super(props);

    this.state = {
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    if (!this.state.mounted) {
      return null;
    }

    return (
      <Layout>
        <Router>
          <Route Component={Portfolio} path="/virtual-portfolio" />
          <Route
            Component={PortfolioAddInstrument}
            path="/virtual-portfolio/add/:friendlyInstrumentType/:slug/:friendlyInstrumentId"
          />
          <Route
            Component={PortfolioPage}
            path="/virtual-portfolio/:portfolioId"
          />
          <Route
            Component={PortfolioPage}
            path="/virtual-portfolio/:portfolioId/page/:pageNumber"
          />
          <Route Component={Redirect} default to="/virtual-portfolio" />
        </Router>
      </Layout>
    );
  }
}

export default VirtualPortfolioPage;
